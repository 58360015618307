import React from 'react';
import {useForm} from 'react-hook-form';
import {withRouter} from 'react-router-dom';
import {useStateMachine} from 'little-state-machine';
import updateAction from 'pages/Authentication/actions/updateAction';
import authStyle from 'pages/Authentication/Styles/AuthStyle';
import {Button, TextField} from '@material-ui/core';
import FormWrapper from 'pages/Authentication/components/FormWrapper';
import ValidationSchema from './login-validation';
import {yupResolver} from '@hookform/resolvers/yup';
import ValidationError from 'components/ValidationError/ValidationError';
import AuthService from 'services/auth-service';
import {useSnackbar} from 'notistack';
import {parseApiError} from 'services/utils';

const authService = new AuthService();

const Login = (props) => {
    const classes = authStyle();
    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(ValidationSchema),
        shouldFocusError: true,
        defaultValues: {
            // 'USERNAME': 'gabor.nemeth@xstead.com',
            // 'PASSWORD': 'bumbika12'
        }
    });
    const {enqueueSnackbar} = useSnackbar();

    const {actions, state} = useStateMachine({updateAction});
    const onSubmit = (data) => {
        authService.login(data)
            .then(authServiceRes => {

                let res = authServiceRes.data;

                if (authServiceRes.status === 200) {

                    if (res.STATE === 'SETUP') {

                        actions.updateAction({
                            ...data,
                            'USER_ID': res.USER_ID
                        });
                        props.history.push('./verifyphonenumber');

                    } else if (res.STATE === 'NORMAL') {

                        actions.updateAction({
                            ...data,
                            'STATE': res.STATE
                        });
                        authService.verify2fa({
                            ...data,
                            'STATE': res.STATE,
                            'USER_ID': res.USER_ID
                        }).then(res => {
                            if (res.data.success === true) {
                                props.history.push('./verify2facode');
                            }
                        });

                    } else {
                        let error = parseApiError(res);
                        enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                    }

                } else {
                    enqueueSnackbar('Hiba történt a bejelentkezés során!', {variant: 'error'});
                }

            })
            .catch(error => {
                enqueueSnackbar('Hiba történt a bejelentkezés során!', {variant: 'error'});
            });
    };

    return (
        <FormWrapper>
            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="USERNAME"
                    label="E-mail cím"
                    name="USERNAME"
                    autoComplete="email"
                    autoFocus
                    inputRef={register}
                    defaultValue={state.USERNAME}
                />
                {errors.USERNAME && <ValidationError message={errors.USERNAME.message}/>}

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="PASSWORD"
                    label="Jelszó"
                    type="password"
                    id="PASSWORD"
                    autoComplete="current-password"
                    inputRef={register}
                    defaultValue={state.password}
                />
                {errors.PASSWORD && <ValidationError message={errors.PASSWORD.message}/>}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                >
                    Belépés
                </Button>
            </form>
        </FormWrapper>
    );
};

export default withRouter(Login);
