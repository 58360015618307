import BaseAxiosService from 'services/base-axios-service';
import {EVENT_SERVICE} from 'services/constants/service-constants';

export default class EventService extends BaseAxiosService {
    constructor() {
        super(EVENT_SERVICE);
    }

    fetchEvents(filter) {
        return this.getRequest(`q/page:${filter.page};limit:${filter.pageSize}`);
    }
}
