import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {Edit, Trash2 as DeleteIcon} from 'react-feather';
import recipientsActionButtonsStyle from 'pages/Recipients/List/Styles/ActionButtonsStyle';

const RecipientsActionButtons = ({data, handleDelete, handleOpen, setDataId}) => {
    const classes = recipientsActionButtonsStyle();

    return (
        <div className={classes.buttonContainer}>
            <IconButton
                onClick={() => handleDelete(data.ID)}
                className={classes.buttonDanger}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
            >
                <DeleteIcon className={classes.buttonDetails} size={18} />
            </IconButton>
            <IconButton
                className={classes.button}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
                onClick={() => {
                    handleOpen();
                    setDataId(data.ID);
                }}
            >
                <Edit size={18} />
            </IconButton>
        </div>
    );
};

export default RecipientsActionButtons;
