import {makeStyles} from '@material-ui/styles';

const actionsStyle = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        margin: theme.spacing(0),
        padding: '0px 5px',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    buttonDetails: {
        color: theme.palette.text.listaction,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    menuItem: {
        fontSize: '10pt'
    }
}));

export default actionsStyle;
