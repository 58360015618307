import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {withRouter} from 'react-router-dom';
import {useStateMachine} from 'little-state-machine';
import updateAction from 'pages/Authentication/actions/updateAction';
import {Button, TextField, Typography} from '@material-ui/core';
import ValidationError from 'components/ValidationError/ValidationError';
import FormWrapper from 'pages/Authentication/components/FormWrapper';
import {yupResolver} from '@hookform/resolvers/yup';
import ValidationSchema from './phone-validation';
import authStyle from 'pages/Authentication/Styles/AuthStyle';
import AuthService from 'services/auth-service';
import InputMask from 'react-input-mask';
import {parseApiError} from 'services/utils';
import {useSnackbar} from 'notistack';

const authService = new AuthService();

const VerifyPhoneNumber = (props) => {
    const classes = authStyle();
    const {register, handleSubmit, control, errors, setError} = useForm({
        resolver: yupResolver(ValidationSchema),
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
    });
    const {state} = useStateMachine({updateAction});
    const {enqueueSnackbar} = useSnackbar();

    const onSubmit = (data) => {

        // console.log(data);
        // console.log('+' + data.PHONE.replace(/\D/g, ''));
        authService.verify2fa({
            ...state,
            ...{
                PHONE: '+' + data.PHONE.replace(/\D/g, '')
            },
            STATE: 'SETUP'
        }).then(authServiceRes => {

            let res = authServiceRes.data;
            if (authServiceRes.status === 200) {
                // console.log(res);

                if (res.success === true) {
                    props.history.push('./verify2facode');
                } else {
                    let error = parseApiError(res);
                    // console.log(error);
                    setError('PHONE', {
                        message: error.message.map((e) => e.val).join('\n')
                    });
                }

            } else {
                let error = parseApiError(res);
                enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
            }

        });
    };

    return (
        <FormWrapper>

            <Typography component="p" className={classes.infoText}>
                Kérjük add meg a telefonszámodat, hogy aktiválni tudjuk a kétlépcsős azonosítást a felhasználói fiókodhoz.
                Ügyelj a telefonszámod pontos megadására. A telefonszámot országhívószám nélkül az alábbi formátumban
                szükséges megadni:
                <Typography component="span" style={{display:'block', marginTop: '5px'}}>+36 (__) ___-____</Typography>
            </Typography>

            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <InputMask
                    mask="+36 (99) 999-9999"
                    onChange={props.onChange} value={props.value}
                >
                    {() =>
                        <TextField
                            type="tel"
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="PHONE"
                            label="Telefonszám"
                            // placeholder="+36 (xx) xxx-xxxx"
                            name="PHONE"
                            autoFocus
                            defaultValue={state.PHONE}
                            className={classes.field}
                        />}
                </InputMask>
                {errors.PHONE && <ValidationError message={errors.PHONE.message}/>}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.submit}
                >
                    Kód igénylése
                </Button>
            </form>
        </FormWrapper>
    );
};

export default withRouter(VerifyPhoneNumber);
