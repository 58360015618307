import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box, TextField} from '@material-ui/core';
import ValidationError from 'components/ValidationError/ValidationError';
import {LoadingButton} from '@material-ui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import PasswordValidationSchema from 'pages/PasswordReset/components/password-validation';
import {Save as SaveIcon} from 'react-feather';

const PasswordResetForm = ({submit,loading, classes}) => {
    const {
        handleSubmit,
        errors,
        control,
    } = useForm({
        resolver: yupResolver(PasswordValidationSchema),
        reValidateMode: 'onSubmit',
        defaultValues: {
            OLD_PASSWORD: '',
            NEW_PASSWORD: '',
            NEW_CHECK_PASSWORD: ''
        }
    });

    return (
        <form noValidate onSubmit={handleSubmit(submit)}>

            <Controller
                name="OLD_PASSWORD"
                control={control}
                as={TextField}
                id="old-password"
                label="Jelenlegi jelszó"
                className={classes.field}
                margin="normal"
                fullWidth
                variant="outlined"
                type="password"
                required
            />
            {errors.OLD_PASSWORD && <ValidationError message={errors.OLD_PASSWORD.message}/>}

            <Controller
                name="NEW_PASSWORD"
                control={control}
                as={TextField}
                id="password"
                label="Új jelszó"
                className={classes.field}
                margin="normal"
                fullWidth
                variant="outlined"
                type="password"
                required
            />
            {errors.NEW_PASSWORD && <ValidationError message={errors.NEW_PASSWORD.message}/>}

            <Controller
                name="NEW_CHECK_PASSWORD"
                control={control}
                as={TextField}
                id="passwordConfirm"
                label="Új jelszó megerősítése"
                type="password"
                fullWidth
                margin="normal"
                variant="outlined"
                className={classes.field}
                required
            />
            {errors.NEW_CHECK_PASSWORD && <ValidationError message={errors.NEW_CHECK_PASSWORD.message}/>}

            <Box className={classes.submitButtonWrapper}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.field}
                    pending={loading}
                    startIcon={<SaveIcon size={15} />}
                >
                    Módosítás
                </LoadingButton>
            </Box>
        </form>
    );
};

export default PasswordResetForm;
