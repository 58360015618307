import React, {useState} from 'react';
import {useDialogState} from 'utils/hooks';
import {usePromiseTracker} from 'react-promise-tracker';
import {GROUP_SERVICE} from 'services/constants/service-constants';
import BasicTable from 'components/Table';
import UserService from 'services/user-service';
import MassActions from 'pages/Recipients/List/components/MassActions';
import NewRecipient from 'pages/Recipients/Edit';
import RecipientsActionButtons from 'pages/Recipients/List/components/ActionButtons';

const userService = new UserService();

const RecipientsListPage = () => {
    const [users,setUsers] = useState([]);
    const [total,setTotal] = useState(0);
    const [dataId, setDataId] = useState(0);
    const {open, handleOpen, handleClose} = useDialogState();
    const {promiseInProgress: loading} = usePromiseTracker({area: GROUP_SERVICE});

    const columns = [
        {
            key: 'USER_OBJECT.EMAIL',
            title: 'E-mail cím',
            columnWidth: '200px',
        },
        {
            key: 'USER_OBJECT.NAME',
            title: 'Név',
            flex: 1,
        },
        {
            key: 'actionButtons',
            title: 'Eszközök',
            columnWidth: '100px',
        }
    ];

    const fetchRecipients = ({page ,pageSize}) => {
        const filter = {page: page,limit: pageSize, q: ''};
        userService.fetchAddressbook(filter).then(res => {
            if (res.data.result) {
                setUsers(res.data.result);
                setTotal(res.data.total || 0);
            }
        });
    };

    const handleDelete = (dataId) => {
        console.log(dataId);
        // TODO: put here the delete end-point
    };

    return (
        <div>
            <MassActions handleOpen={handleOpen} />
            <BasicTable
                columns={columns}
                dataSource={users}
                fetchDataSource={fetchRecipients}
                ActionButtons={RecipientsActionButtons}
                handleOpen={handleOpen}
                handleDelete={handleDelete}
                loading={loading}
                setDataId={setDataId}
                total={total}
            />
            <NewRecipient
                open={open}
                handleClose={handleClose}
                dataId={dataId}
                setDataId={setDataId}
                fetchDataSource={fetchRecipients}
            />
        </div>
    );
};

export default RecipientsListPage;
