import React from 'react';
import {createContext, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export const ApiHealthContext = createContext({});

const ApiHealthProvider = ({children}) => {
    const location = useLocation().pathname;

    useEffect(() => {
        // TODO: put here the api health check call
        // handling of 401 is in AuthContext
    }, [location]);


    const store = useMemo(() => ({}), []);
    return <ApiHealthContext.Provider value={store}>{children}</ApiHealthContext.Provider>;
};

export default ApiHealthProvider;
