import React from 'react';
import routes from '../../../routes';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {Divider, IconButton, Box, Drawer, Typography, makeStyles, useMediaQuery} from '@material-ui/core';
import NavItem from './NavItem';
import SpinnerLoader from 'components/Spinner/Spinner';


const SideMenu = ({classes, open, handleDrawerOpen, handleDrawerClose}) => {

    const getMenuGroups = (routes) => {
        let groups = [];
        routes.map((route,idx) => {
            let _chk = groups.find(groups => groups.groupid === route.menuGroup);
            if ( route.menuGroup && !_chk ) {
                groups.push({
                    title: route.groupLabel,
                    groupid: route.menuGroup
                });
            }
        });
        return groups;
    };
    const minHeight = useMediaQuery('(min-height:900px)');

    const getMenuGroupTitle = (route,idx) => (
        <div key={route.groupLabel} style={{marginTop:(idx>1)?10:0}}>
            <Typography
                className={classes.name}
                color="textPrimary"
                variant="h5"
            >
                {route.groupLabel}
            </Typography>
            {/*<Divider />*/}
            {/*<NavItem*/}
            {/*    href={route.path}*/}
            {/*    key={idx}*/}
            {/*    title={route.label}*/}
            {/*    icon={route.Icon}*/}
            {/*/>*/}
        </div>
    );

    //
    // if (route.menuGroup !== routes[idx-1].menuGroup) {
    //     let title = getMenuGroupTitle(route,idx);
    // }

    const getMenuItems = (groupid) => {
        return routes.map((route,idx) => {
            if (route.area === 'left' && route.menuGroup === groupid) {
                return (
                    <NavItem
                        href={route.path}
                        key={idx}
                        title={route.label}
                        icon={route.Icon}
                        style={{padding:'0px'}}
                    />
                );
            }
        });
    };

    const getMenu = () => {

        return getMenuGroups(routes).map((group, idx) => {

            return (
                <div key={group.groupid}>
                    <Typography
                        className={classes.name}
                        color="textPrimary"
                        variant="h5"
                        style={{marginTop:(idx>0)?15:0, marginBottom:10}}
                    >
                        {group.title}
                    </Typography>

                    <>
                        {getMenuItems(group.groupid)}
                    </>

                </div>
            );

        });



    };

    const content = (
        <Box>

            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Typography
                    className={classes.name}
                    color="textPrimary"
                    variant="h5"
                >
                    {'Tárhely'}
                </Typography>
                <Typography
                    color="textSecondary"
                    variant="body2"
                >
                    {'Dunacorp Zrt.'}
                </Typography>

            </Box>
            <Divider/>
            <Box p={2}>
                <List>
                    {getMenu()}
                </List>
            </Box>

            <Box flexGrow={1}/>
        </Box>
    );

    return (
        <>
            <Drawer
                className={classes.drawer}
                anchor="left"
                classes={{paper: classes.drawerPaper}}
                open={open}
                variant="persistent"
            >
                {content ? content : <SpinnerLoader />}
                {minHeight && <div className={classes.version}>Verzió: {process.env.REACT_APP_VERSION}</div>}
            </Drawer>
        </>
    );
};

export default SideMenu;

// if (!route.indexPage && route.area === 'left') {
//     return (
//         <Link to={route.path} key={idx} style={{textDecoration: 'none', color: 'inherit'}}>
//             <ListItem button key={route.path}>
//                 <ListItemIcon>
//                     <route.Icon/>
//                 </ListItemIcon>
//                 <ListItemText>{route.label}</ListItemText>
//             </ListItem>
//         </Link>);
// }
