import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Trash2 as DeleteIcon, PlusCircle, CornerDownLeft} from 'react-feather';
import MoveMenu from 'pages/Messages/List/components/MoveMenu';
import massActionsStyle from 'pages/Messages/List/Styles/MassActionsStyle';
import {Link} from 'react-router-dom';
import MessagesService from 'services/messages-service';

const messageService = new MessagesService();

const MassActions = ({selectedRows, fetchDataSource, pagination}) => {
    const classes = massActionsStyle();
    const theme = useTheme();

    const handleMove = (messageIds, moveFolderId) => {
        if (!messageIds) return;
        const intMessageIds = messageIds.map(id => +id);
        messageService.moveMessages({DATA_ID: intMessageIds, FOLDER_ID: moveFolderId})
            .then(() => fetchDataSource(pagination));
    };

    const handleDelete = (messageIds) => {
        if (!messageIds) return;
        const intMessageIds = messageIds.map(id => +id);
        messageService.deleteMessagesByIds({DATA_ID: intMessageIds})
            .then(() => fetchDataSource(pagination));
    };

    return (
        <div className={classes.buttonContainer}>
            <IconButton
                className={classes.button}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
            >
                <Link to="/messages/new" className={classes.linkItem}>
                    <PlusCircle className={classes.buttonDetails} size={18}/>
                </Link>
            </IconButton>
            <MoveMenu
                theme={theme}
                classes={classes}
                handleMove={handleMove} messageIds={selectedRows} />
            <IconButton
                onClick={() => handleDelete(selectedRows)}
                className={classes.buttonDanger}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
                disabled={selectedRows && selectedRows.length === 0}
            >
                <DeleteIcon className={classes.buttonDetails} size={18} />
            </IconButton>
            <IconButton
                className={classes.button}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
                disabled={selectedRows && (selectedRows.length > 1 || selectedRows.length === 0)}
            >
                <Link to={`/messages/reply/${selectedRows[0]}`} className={classes.linkItem}>
                    <CornerDownLeft className={classes.buttonDetails} size={18}/>
                </Link>
            </IconButton>
        </div>
    );
};

export default MassActions;
