import {Typography} from '@material-ui/core';
import React from 'react';

export const formatUserObject = (data) => {
    if (data.NAME !== '') {
        return (
            <>
                <Typography style={{fontWeight: 'bold'}} component="span">
                    {data.NAME}
                </Typography>
                <Typography component="span">
                    {` <${data.EMAIL}>`}
                </Typography>
            </>
        );
    } else {
        return (
            <Typography style={{fontWeight: 'bold'}} component="span">
                {`<${data.EMAIL}>`}
            </Typography>
        );
    }
};

export const formatUserObjectAsText = (data) => {
    if (data.NAME !== '') {
        return `${data.NAME} <${data.EMAIL}>`;
    } else {
        return data.EMAIL;
    }
};
