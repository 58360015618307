import axios from '../utils/axios';
import {trackPromise} from 'react-promise-tracker';

export default class BaseAxiosService {
    constructor(baseApiEndpoint) {
        this.baseApiEndpoint = baseApiEndpoint;
        this.axios = axios;
    }

    getHeader() {
        return {headers: {Authorization: `Bearer ${localStorage.getItem('access_token')}`}};
    }

    getBasicHeader() {
        const encodedUsername = btoa(process.env.REACT_APP_API_BASIC_USER);
        const encodesPassword = btoa(process.env.REACT_APP_API_BASIC_PW);
        return {headers: {Authorization: `Basic ${encodedUsername}${encodesPassword}`}};
    }

    getRequest(url, queryParams = {}, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.get(`${this.baseApiEndpoint}/${url}`, this.getHeader(), {
                params: queryParams,
            }),
            trackArea
        );
    }

    getFile(url, queryParams = {}, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.get(
                `${this.baseApiEndpoint}/${url}`,
                {
                    ...this.getHeader(),
                    responseType: 'blob'
                },
                {
                    params: queryParams,
                }),
            trackArea
        );
    }

    getRequestWithBasicHeader(url, queryParams = {}, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.get(`${this.baseApiEndpoint}/${url}`, this.getBasicHeader(), {
                params: queryParams,
            }),
            trackArea
        );
    }

    postRequestWithBasicHeader(url, data, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.post(`${this.baseApiEndpoint}/${url}`, data, this.getBasicHeader()),
            trackArea
        );
    }

    postRequest(url, data, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.post(`${this.baseApiEndpoint}/${url}`, data, this.getHeader()),
            trackArea
        );
    }

    putRequest(url, data, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.put(`${this.baseApiEndpoint}/${url}`, data, this.getHeader()),
            trackArea
        );
    }

    deleteRequest(url, data, trackArea = this.baseApiEndpoint) {
        return trackPromise(
            this.axios.delete(`${this.baseApiEndpoint}/${url}`, data),
            trackArea
        );
    }
}
