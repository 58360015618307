import React from 'react';
import {makeStyles} from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function LinearDeterminate({progresstime, autoProgress}) {
    const classes = useStyles();
    const [progressValue, setProgressValue] = React.useState(0);

    const handleAutoProgress = () => {
        const timer = setInterval(() => {
            setProgressValue((oldProgress) => {
                if (oldProgress === 100) {
                    return 100;
                }
                const diff = parseInt(100 / ((progresstime || 4000) / 200));
                return Math.min(oldProgress + diff, 100);
            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    };

    React.useEffect(() => {
        if (autoProgress === true) {
            handleAutoProgress();
        }
    }, [autoProgress]);

    return (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progressValue}/>
        </div>
    );
}
