import React, {useEffect, useState} from 'react';
import {GROUP_SERVICE} from 'services/constants/service-constants';
import GroupService from 'services/group-service';
import {usePromiseTracker} from 'react-promise-tracker';
import GroupsActionButtons from 'pages/Groups/List/components/ActionButtons';
import MassActions from 'pages/Groups/List/components/MassActions';
import {useDialogState} from 'utils/hooks';
import GroupEditPage from 'pages/Groups/Edit';
import BasicTable from 'components/Table';

const userService = new GroupService();

const GroupListPage = () => {
    const [groups, setGroups] = useState([]);
    const [dataId, setDataId] = useState(0);
    const [total, setTotal] = useState(0);
    const {open, handleOpen, handleClose} = useDialogState();
    const {promiseInProgress: loading} = usePromiseTracker({area: GROUP_SERVICE});

    const columns = [
        {
            key: 'NAME',
            title: 'Csoport név',
            flex: 1
        },
        {
            key: 'actionButtons',
            title: 'Eszközök',
            columnWidth: '100px',
        }
    ];

    const fetchGroups = ({page, pageSize}) => {
        const filter = {page, pageSize};
        filter.listMode = 'edit';
        userService.fetchUsersGroups(filter).then(res => {
            if (res.data.result) {
                const groups = res.data.result;
                setGroups(groups);
                setTotal(res.data.total || 0);
            }
        });
    };

    useEffect(() => {
        if (!open) {
            fetchGroups({page: 1, pageSize: 10});
        }
    }, [open]);

    const handleDelete = (dataId) => {
        console.log(dataId);
        // TODO: put here the delete end-point
    };

    return (
        <div>
            <MassActions handleOpen={handleOpen}/>
            <BasicTable
                columns={columns}
                dataSource={groups}
                fetchDataSource={fetchGroups}
                ActionButtons={GroupsActionButtons}
                handleOpen={handleOpen}
                handleDelete={handleDelete}
                loading={loading}
                setDataId={setDataId}
                total={total}
            />
            <GroupEditPage
                open={open}
                handleClose={handleClose}
                dataId={dataId}
                setDataId={setDataId}
            />
        </div>
    );
};

export default GroupListPage;
