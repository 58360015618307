import React, {useState} from 'react';
import {IconButton, Menu, MenuItem, Typography} from '@material-ui/core';
import {ArrowRight as ArrowRightIcon, Eye as EyeIcon, Trash2 as DeleteIcon} from 'react-feather';
import {ARCHIVED_FOLDER_ID, DELETED_FOLDER_ID} from 'utils/constants/messages';

const MoveMenu = ({handleMove, messageIds, theme, classes}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
                className={classes.button}
                disabled={messageIds && messageIds.length === 0}
            >
                <ArrowRightIcon className={classes.buttonDetails} size={18} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Typography className={classes.moveTypography}>Áthelyezés</Typography>
                <MenuItem onClick={() => {
                    handleMove(messageIds, DELETED_FOLDER_ID);
                    handleClose();
                }} className={classes.menuItem}>Törölt</MenuItem>
                <MenuItem onClick={() => {
                    handleMove(messageIds, ARCHIVED_FOLDER_ID);
                    handleClose();
                }} className={classes.menuItem}>Archivált</MenuItem>
            </Menu>
        </>
    );
};

export default MoveMenu;
