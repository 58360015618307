import {makeStyles} from '@material-ui/styles';

const groupActionButtonsStyle = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        padding: theme.spacing(1),
        color: theme.palette.text.listaction,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            // color: 'white',
            // borderRadius: '10px',
        }
    },
    buttonDanger: {
        padding: theme.spacing(1),
        color: theme.palette.text.listaction,
        '&:hover': {
            color: theme.palette.danger,
            backgroundColor: 'transparent',
            // backgroundColor: theme.palette.danger,
            // color: 'white',
            borderRadius: '10px',
        }
    },
    buttonDetails: {
        color: 'inherit'
    },
    linkItem: {
        textDecoration: 'none',
        display: 'flex',
        color: 'inherit',
    }
}));

export default groupActionButtonsStyle;
