import React, {useState} from 'react';
import {Autocomplete, CircularProgress} from '@material-ui/core';
import {Chip, TextField} from '@material-ui/core';
import {Controller} from 'react-hook-form';
import {usePromiseTracker} from 'react-promise-tracker';
import {USER_SERVICE} from 'services/constants/service-constants';
import UserService from 'services/user-service';
import GroupService from 'services/group-service';

const groupService = new GroupService();

const FetchGroupSelect = (
    {
        name,
        required,
        control,
        setValue,
        style,
        classes,
        isHidden
    }) => {
    const [group, setGroup] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchGroups = (name) => {
        setLoading(true);
        groupService.fetchUserGroupByName(name)
            .then(res => {
                const {result} = res.data;
                setGroup(
                    result
                );
                setLoading(false);
            });
    };

    const setGroupField = values => {
        setValue(name, values.ID);
    };

    const renderOption = option => {
        return `${option.NAME}`;
    };

    const renderTags = (option, index, getTagProps) => {
        const label = renderOption(option);
        return (
            <Chip label={label} {...getTagProps({index})} className={classes.chips} />
        );
    };

    return (
        <Controller
            name={name}
            render={(props) => (
                <Autocomplete
                    hidden={isHidden||false}
                    loading={loading}
                    loadingText="Adatok betöltése..."
                    title="Csoport"
                    id="tags-filled"
                    options={group || []}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) {
                            fetchGroups(newInputValue);
                        }
                    }}
                    getOptionSelected={(option,value) => option.NAME === value.NAME}
                    autoComplete={false}
                    getOptionLabel={option => renderOption(option)}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => (
                            renderTags(option, index, getTagProps)
                        )))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoComplete="off"
                            label="Csoport"
                            required={required}
                            placeholder="Csoport név"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    onChange={(event, value) => {
                        if (value) {
                            setGroupField(value);
                        }
                    }}
                    className={style || ''}
                />
            )}
            control={control}
        />
    );
};
export default FetchGroupSelect;
