import * as yup from 'yup';

export default yup.object().shape({
    TITLE: yup.string().required('A tárgy megadása kötelező!'),
    RECIPIENTS: yup.array()
        .when('IS_GROUP_MESSAGE', {
            is: false,
            then:
                yup.array().min(1,'A címzett megadása kötelező!')
                    .of(
                        yup.object().shape({
                            EMAIL: yup.string().email('Hibás e-mail címet adott meg!')
                        }))
        }),
    GROUP_IDS: yup.number()
        .when('IS_GROUP_MESSAGE', {
            is: true,
            then: yup.number().min(1,'A csoport megadása kötelező!').required('A csoport megadása kötelező!')
        }),
    FILES_COUNT: yup.number().min(1,'Hiányzik a csatolmány!').typeError('Hiányzik a csatolmány!')
});

