import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {AlertTriangle as EmptyIcon} from 'react-feather';
import {useTheme} from '@material-ui/core/styles';

const EmptyRow = ({columnsLength}) => {

    const theme = useTheme();

    return (
        <TableRow>
            <TableCell colSpan={columnsLength} style={{textAlign: 'center'}} align="center">
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', color: theme.palette.text.hint}}>
                    <EmptyIcon />
                    <span style={{paddingLeft: '10px'}}>Nincs megjeleníthető adat...</span>
                </div>
            </TableCell>
        </TableRow>
    );

};

export default EmptyRow;
