import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ValidationSchema from 'pages/Me/components/profile-validation';
import get from 'lodash/get';
import {Box, TextField} from '@material-ui/core';
import ValidationError from 'components/ValidationError/ValidationError';
import SpinnerLoader from 'components/Spinner/Spinner';
import {LoadingButton} from '@material-ui/lab';
import InputMask from 'react-input-mask';
import {Save as SaveIcon} from 'react-feather';


const ProfileEditForm = ({submit,user, loading, classes}) => {
    const { handleSubmit, errors, control, reset } = useForm({
        resolver: yupResolver(ValidationSchema),
        reValidateMode: 'onSubmit',
        defaultValues: {
            LAST_NAME: get(user,'LAST_NAME', ''),
            FIRST_NAME: get(user,'FIRST_NAME', ''),
            EMAIL: get(user,'EMAIL', ''),
            PHONE_2FA: get(user,'PHONE_2FA', '')
        }
    });

    useEffect(() => {
        reset(user);
    }, [user]);

    if (!user) return <SpinnerLoader />;

    return (
        <form noValidate onSubmit={handleSubmit(submit)} >
            <Controller
                name="EMAIL"
                control={control}
                as={TextField}
                id="email"
                label="E-mail cím"
                // placeholder="E-mail cím"
                fullWidth
                margin="normal"
                InputLabelProps={{
                }}
                variant="outlined"
                className={classes.field}
                disabled
            />
            <Controller
                name="LAST_NAME"
                control={control}
                as={TextField}
                id="last_name"
                label="Vezetéknév"
                // placeholder="Vezetéknév"
                fullWidth
                margin="normal"
                InputLabelProps={{
                }}
                variant="outlined"
                className={classes.field}
                defaultValue=""
                required
            />
            {errors.LAST_NAME && <ValidationError message={errors.LAST_NAME.message}/>}
            <Controller
                name="FIRST_NAME"
                control={control}
                as={TextField}
                id="first_name"
                label="Keresztnév"
                // placeholder="Keresztnév"
                fullWidth
                margin="normal"
                InputLabelProps={{
                }}
                variant="outlined"
                className={classes.field}
                required
            />
            {errors.FIRST_NAME && <ValidationError message={errors.FIRST_NAME.message}/>}

            <Controller
                name="PHONE_2FA"
                control={control}
                as={
                    <InputMask
                        mask="+36 (99) 999-9999"
                    >
                        {(props) =>
                            <TextField {...props} />
                        }
                    </InputMask>
                }
                id="phone_2fa"
                label="Telefonszám"
                fullWidth={true}
                margin="normal"
                InputLabelProps={{}}
                variant="outlined"
                className={classes.field}
                required={true}
            />
            {errors.PHONE_2FA && <ValidationError message={errors.PHONE_2FA.message}/>}

            <Box className={classes.submitButtonWrapper}>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.field}
                    loading={loading}
                    startIcon={<SaveIcon size={15} />}
                >
                        Mentés
                </LoadingButton>
            </Box>
        </form>
    );
};

export default ProfileEditForm;
