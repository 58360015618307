import BaseAxiosService from 'services/base-axios-service';
import {GROUP_SERVICE} from 'services/constants/service-constants';

export default class GroupService extends BaseAxiosService {
    constructor() {
        super(GROUP_SERVICE);
    }

    fetchUsersGroups(filter) {
        return this.getRequest(`q/page:${filter.page};limit:${filter.pageSize};listmode:${filter.listMode||''}`);
    }

    fetchUserGroupByName(name) {
        return this.getRequest(`q/name:${name}`);
    }

    fetchGroupData(id) {
        return this.getRequest(`${id}`);
    }

    createGroup(data) {
        return this.postRequest('', data);
    }

    createGroupMember(data) {
        return this.postRequest('member', data);
    }

    updateGroup(data,groupId) {
        return this.putRequest(`${groupId}`, data);
    }
}
