import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
    loader: {
        margin: '0 auto',
        display: 'block'
    }
});

const SpinnerLoader = () => {
    const classes = useStyles();
    return <CircularProgress className={classes.loader} />;
};

export default SpinnerLoader;
