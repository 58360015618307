import * as yup from 'yup';

export default yup.object().shape({
    LAST_NAME: yup.string()
        .required('A vezetéknév megadása kötelező!'),
    FIRST_NAME: yup.string()
        .required('A keresztnév megadása kötelező!'),
    PASSWORD: yup.string()
        .required('A jelszó megadása kötelező!'),
    PASSWORD_RE: yup.string()
        .required('A jelszó ismételt megadása kötelező!'),
    REGISTRATION_CODE: yup.string()
        .required('A regisztrációs kód megadása kötelező!'),
});
