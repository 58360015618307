import React, {useCallback, useEffect, useState} from 'react';
import {Autocomplete, CircularProgress} from '@material-ui/core';
import {Chip, TextField} from '@material-ui/core';
import {Controller} from 'react-hook-form';
import UserService from 'services/user-service';
import debounce from 'lodash/debounce';

const userService = new UserService();

const FetchSelect = (
    {
        initValue,
        name,
        required,
        control,
        setValue,
        style,
        classes,
        isHidden
    }) => {
    const [users, setUsers] = useState([]);
    const [selectedValue, setSelectedValue] = useState([{}]);
    const [loading, setLoading] = useState(false);

    const fetchUsers = (query) => {
        setLoading(true);
        userService.fetchAddressbook({
            q: query
        }).then(res => {
            const {result} = res.data;
            // filter out selected items
            setUsers(
                result.filter(item =>
                    !selectedValue.map(r => r.UID).includes(item.ID))
            );
            setLoading(false);
        });
    };

    const delayedSearch = useCallback(
        debounce(value => fetchUsers(value), 500),
        []
    );

    useEffect(() => {
        if (initValue) {
            const recipients = initValue.map(recipient => (
                {EMAIL: recipient.EMAIL, UID: recipient.ID || 0}
            ));
            setSelectedValue(recipients);
            setValue(name, recipients);
        }

        return () => {
            delayedSearch.cancel();
        };
    }, [initValue]);

    const setEmailsField = values => {
        /**
         * @param value.USER_OBJECT
         */
        const recipients = values.map(value => (
            {EMAIL: value?.USER_OBJECT?.EMAIL || value, UID: value?.USER_OBJECT?.ID || 0}
        ));
        setSelectedValue(recipients);
        setValue(name, recipients);
    };

    const renderOption = option => {
        if (typeof option !== 'object') {
            return `${option}`;
        }
        return `${option?.USER_OBJECT?.NAME || option.NAME} <${option?.USER_OBJECT?.EMAIL || option.EMAIL}>`;
    };

    const renderTags = (option, index, getTagProps) => {
        const label = renderOption(option);
        return (
            <Chip label={label} {...getTagProps({index})} className={classes.chips}/>
        );
    };

    return (
        <Controller
            name={name}
            render={(props) => (
                <Autocomplete
                    hidden={isHidden || false}
                    loading={loading}
                    loadingText="Adatok betöltése..."
                    multiple
                    title="Címzett"
                    id="tags-filled"
                    options={users || []}
                    onInputChange={(event, newInputValue) => {
                        delayedSearch(newInputValue);
                    }}
                    freeSolo
                    autoSelect
                    filterSelectedOptions
                    defaultValue={initValue?.map(recipient => (
                        {EMAIL: recipient.EMAIL, UID: recipient.ID, NAME: recipient.NAME}
                    )) || []}
                    autoComplete={false}
                    getOptionLabel={option => renderOption(option)}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => (
                            renderTags(option, index, getTagProps)
                        )))}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoComplete="off"
                            label="Címzett"
                            required={required}
                            placeholder="Név vagy e-mail cím..."
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    onChange={(event, value) => {
                        setEmailsField(value);
                    }}
                    className={style || ''}
                />
            )}
            control={control}
        />
    );
};

export default FetchSelect;
