import React from 'react';
import {createContext, useEffect, useMemo, useState} from 'react';
import axios from '../../utils/axios';
import {useHistory} from 'react-router-dom';

export const AuthContext = createContext({
    accessToken: null,
    logInCompleted: false,
    changeAccessToken: () => null,
    changeRefreshToken: () => null,
    updateLogInCompleted: () => null
});

const AuthProvider = ({children}) => {
    const initialToken = () => localStorage.getItem('access_token') || null;
    const initialRefreshToken = () => localStorage.getItem('refresh_token') || null;
    const initialLogInCompleted = () => localStorage.getItem('login_completed') || null;
    const [accessToken, setAccessToken] = useState(initialToken);
    const [logInCompleted, setLogInCompleted] = useState(initialLogInCompleted);
    const [refreshToken, setRefreshToken] = useState(initialRefreshToken);

    const history = useHistory();

    useEffect(() => {
        if (accessToken || refreshToken) {
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshToken);
            return;
        }
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
    }, [accessToken, refreshToken]);

    useEffect(() => {
        if (logInCompleted) {
            localStorage.setItem('login_completed', true);
            return;
        }
        localStorage.removeItem('login_completed');
    }, [logInCompleted]);

    const changeAccessToken = (token) => setAccessToken(token);
    const changeRefreshToken = (token) => setRefreshToken(token);
    const updateLogInCompleted = (value) => setLogInCompleted(value);

    const store = useMemo(() => ({
        accessToken,
        logInCompleted,
        updateLogInCompleted,
        changeAccessToken,
        changeRefreshToken
    }), [accessToken, logInCompleted]);

    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const {status_code, error_code, error_message} = error.response.data;
            // console.log('response:', error.response);
            // console.log('status:', error.response.status);
            // console.log('error_code:', error_code);
            if (
                error.response &&
                error.response.status === 401 &&
                (error_code === 'authError' || error_code === 'tokenExpired')
            ) {
                changeAccessToken(null);
                changeRefreshToken(null);
                updateLogInCompleted(null);
                history.push('/auth/login');
            }

            // eslint-disable-next-line no-undef
            return Promise.reject(error);
        }
    );

    return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
