import React, {useEffect, useState} from 'react';
import {TextField, Box, FormControlLabel, Checkbox} from '@material-ui/core';
import {LoadingButton} from '@material-ui/lab';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import ValidationSchema from './message-validation';
import FetchSelect from './FetchSelect';
import messageFormStyle from './MessageFormStyle';
import FileUploadDropZone from './FileUploadDropZone';
import ValidationError from 'components/ValidationError/ValidationError';
import {Send as SaveIcon} from 'react-feather';
import get from 'lodash/get';
import FetchGroupSelect from 'pages/Messages/Edit/components/FetchGroupSelect';

const MessageEditForm = (
    {
        submit,
        handleUpload,
        removeFileFromUpload,
        message,
        filesCount,
        loading
    }) => {
    const [isGroupRecipients, setIsGroupRecipients] = useState(false);
    const initMessageTitle = message ? 'RE: ' + get(message, 'MESSAGE_DATA_OBJECT.TITLE') : 'Általános dokumentum';

    const {control, handleSubmit, errors, setValue} = useForm({
        resolver: yupResolver(ValidationSchema),
        shouldFocusError: true,
        defaultValues: {
            RECIPIENTS: [],
            TITLE: initMessageTitle,
            DETAILS: '',
            TYPE_ID: 1,
            FILES_COUNT: 0,
            REPLY_TO: message?.ID || undefined,
            GROUP_IDS: 0,
            IS_GROUP_MESSAGE: false
        }
    });

    const classes = messageFormStyle();

    useEffect(() => {
        setValue('FILES_COUNT', filesCount);
    }, [filesCount]);

    let recipients_error = false;
    if (errors.RECIPIENTS) {
        if (Object.prototype.toString.call(errors.RECIPIENTS) === '[object Array]') {
            Object.values(errors.RECIPIENTS[0]).forEach(error => {
                recipients_error = error.message;
            });
        } else {
            recipients_error = errors.RECIPIENTS.message;
        }
    }

    return (
        <form noValidate onSubmit={handleSubmit(submit)} className={classes.form}>

            <FetchSelect
                id='recipients'
                name='RECIPIENTS'
                control={control}
                setValue={setValue}
                style={classes.field}
                classes={classes}
                required={true}
                isHidden={isGroupRecipients}
                initValue={message?.RECIPIENTS}
            />

            {isGroupRecipients && <FetchGroupSelect
                id="group_ids"
                name="GROUP_IDS"
                control={control}
                setValue={setValue}
                style={classes.field}
                classes={classes}
                required={true}
                isHidden={!isGroupRecipients}
            />}

            {recipients_error && <ValidationError message={recipients_error}/>}
            {errors.GROUP_IDS && <ValidationError message={errors.GROUP_IDS.message}/>}

            <FormControlLabel
                style={{marginBottom: 15, marginTop: -5}}
                control={
                    <Controller
                        id='is_group_message'
                        name='IS_GROUP_MESSAGE'
                        control={control}
                        render={(props) => (
                            <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) => {
                                    setIsGroupRecipients(e.target.checked);
                                    return props.onChange(e.target.checked);
                                }}
                            />
                        )}

                    />
                }
                label="Csoportos üzenet"
            />

            <Controller
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                id="title"
                name="TITLE"
                label="Tárgy"
                control={control}
                fullWidth
                defaultValue=""
                className={classes.field}
            />
            {errors.TITLE && <ValidationError message={errors.TITLE.message}/>}

            <Controller
                as={TextField}
                variant="outlined"
                required={false}
                id="details"
                name="DETAILS"
                label="Megjegyzés"
                control={control}
                rowsmax={4}
                rows={2}
                InputProps={{}}
                fullWidth
                defaultValue=""
                multiline
                className={classes.field}
            />

            <FileUploadDropZone
                classes={classes}
                handleUpload={handleUpload}
                removeFileFromUpload={removeFileFromUpload}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            />
            {errors.FILES_COUNT && <ValidationError message={errors.FILES_COUNT.message}/>}

            <Controller
                as={TextField}
                id="files_count"
                name="FILES_COUNT"
                control={control}
                defaultValue=""
                InputProps={{
                    readOnly: true,
                    type: 'hidden',
                    style: {display: 'none'}
                }}
            />

            <Controller
                as={TextField}
                id="type_id"
                name="TYPE_ID"
                control={control}
                defaultValue=""
                InputProps={{
                    readOnly: true,
                    type: 'hidden',
                    style: {display: 'none'}
                }}
            />

            <Controller
                as={TextField}
                id="reply_to"
                name="REPLY_TO"
                control={control}
                defaultValue=""
                InputProps={{
                    readOnly: true,
                    type: 'hidden',
                    style: {display: 'none'}
                }}
            />

            <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.field}
                    loading={loading}
                    startIcon={<SaveIcon size={15}/>}
                    pendingposition="start"
                >
                    Küldés
                </LoadingButton>
            </Box>
        </form>
    );
};

export default MessageEditForm;
