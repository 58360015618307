import React from 'react';
import Alert from '@material-ui/core/Alert';
import {AlertTriangle as AlertIcon} from 'react-feather';
import {makeStyles} from '@material-ui/styles';

const validationErrorStyles = makeStyles((theme) => ({
    root: {
        color: '#db0707',
        backgroundColor: 'transparent',
        margin: '-8px 0 4px 0',
        padding: '0 0 0 0'
    },
    icon: {
        color: '#db0707',
        marginRight: '5px',
    },
    message: {
        fontWeight: '500',
        // padding: '0px 0 4px 0',
    }
}));

const ValidationError = ({message}) => {

    const classes = validationErrorStyles();

    return (
        <Alert
            icon={<AlertIcon size={20} />}
            severity="error"
            classes={{...classes}}
        >{message}</Alert>
    );
};

export default ValidationError;
