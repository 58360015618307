import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import routes from '../routes';
import MainLayout from '../components/Layout';
import RouteComponent from '../components/RouteComponent';
import {ThemeProvider} from '@material-ui/core';
import theme from '../components/Layout/Styles/Theme';
import { SnackbarProvider } from 'notistack';
import CombinedContext from '../components/Context/CombinedContext';

const renderingRoutes = (routes) => (
    routes.map((route,idx) => {

        /**
       * @param route.subRoutes
       * @param route.isIndexPage
       */
        if (route.subRoutes && route.isIndexPage) {
            return [
                <RouteComponent key={idx} {...route} />,
                renderingRoutes(route.subRoutes)
            ];
        }

        if (route.subRoutes) return renderingRoutes(route.subRoutes);

        return (
            <RouteComponent key={idx} {...route} />
        );
    })
);

const App = () => (
    <ThemeProvider theme={theme}>
        <div className="app" style={{height:'100%'}}>
            <BrowserRouter>
                <SnackbarProvider maxSnack={3} style={{marginBottom: '20px'}}>
                    <CombinedContext>
                        <MainLayout>
                            <Switch>
                                {renderingRoutes(routes)}
                            </Switch>
                        </MainLayout>
                    </CombinedContext>
                </SnackbarProvider>
            </BrowserRouter>
        </div>
    </ThemeProvider>
);

export default App;