import React from 'react';
import {AppBar, Toolbar} from '@material-ui/core';
import routes from '../../../routes';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import TopMenu from './TopMenu';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'components/Logo';

import {
    Menu as MenuIcon,
} from 'react-feather';

const Header = ({classes, handleDrawerOpen, open}) => {

    const getMenu = () => routes.map((route, idx) => {
        if (route.area === 'top') {
            return <TopMenu route={route} key={idx} classes={classes}/>;
        }
    });

    return (
        <AppBar
            // position="fixed"
            elevation={0}
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >
            <Toolbar>
                <RouterLink to="/messages/incoming">
                    <Logo width={40} height={40} />
                </RouterLink>
                <IconButton
                    // color="white"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    // edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon color={'white'} />
                </IconButton>
                <div className={classes.topMenuParent}>
                    {getMenu()}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
