import React, {useState} from 'react';
import BasicTable from 'components/Table';
import EventService from 'services/event-service';

const eventService = new EventService();

const EventsListPage = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const columns = [
        {
            key: 'ID',
            title: 'Sorszám'
        },
        {
            key: 'EVENT_NAME',
            title: 'Esemény',
        },
        {
            key: 'DATE',
            title: 'Dátum',
        }
    ];

    const fetchEvents = ({page, pageSize}) => {
        // TODO: Call the api
        // eventService.fetchEvents({page, pageSize})
        //     .then(res => {
        //         if (res.data.result) {
        //             setEvents(res.data.result);
        //             setTotal(res.data.total || 0);
        //         }
        //     })
        //     .catch(() => {
        //         setLoading(false);
        //     });
    };

    return (
        <BasicTable
            columns={columns}
            dataSource={events}
            fetchDataSource={fetchEvents}
            loading={loading}
            total={total}
        />
    );
};

export default EventsListPage;
