const GRID_DEFAULT_LOCALE_TEXT = {
    rootGridLabel: 'Táblázat',
    noRowsLabel: 'Nincs megjeleníthető adat...',
    errorOverlayDefaultLabel: 'Hiba történt!',

    // Density selector toolbar button text
    toolbarDensity: 'Density',
    toolbarDensityLabel: 'Density',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfortable',

    // Columns selector toolbar button text
    toolbarColumns: 'Oszlopok',
    toolbarColumnsLabel: 'Oszlop kiválasztása',

    // Filters toolbar button text
    toolbarFilters: 'Szűrők',
    toolbarFiltersLabel: 'Szűrők mutatása',
    toolbarFiltersTooltipHide: 'Szűrők elrejtése',
    toolbarFiltersTooltipShow: 'Szűrők mutatása',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`,

    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Letöltés CSV-ként',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Oszlop keresése',
    columnsPanelTextFieldPlaceholder: 'Oszlop neve',
    columnsPanelDragIconLabel: 'Oszlop újrarendezése',
    columnsPanelShowAllButton: 'Összes mutatása',
    columnsPanelHideAllButton: 'Összes elrejtése',

    // Filter panel text
    filterPanelAddFilter: 'Filter hozzáadása',
    filterPanelDeleteIconLabel: 'Törlés',
    filterPanelOperators: 'Operátorok',
    filterPanelOperatorAnd: 'És',
    filterPanelOperatorOr: 'Vagy',
    filterPanelColumns: 'Oszlopok',
    filterPanelInputLabel: 'Érték',
    filterPanelInputPlaceholder: 'Érték szűrése',

    // Filter operators text
    filterOperatorContains: 'tartalmaz',
    filterOperatorEquals: 'egyenlő',
    filterOperatorStartsWith: 'kezdődik',
    filterOperatorEndsWith: 'végződik',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'is after',
    filterOperatorOnOrAfter: 'is on or after',
    filterOperatorBefore: 'is before',
    filterOperatorOnOrBefore: 'is on or before',

    // Column menu text
    columnMenuLabel: 'Menü',
    columnMenuShowColumns: 'Oszlopok mutatása',
    columnMenuFilter: 'Szűrő',
    columnMenuHideColumn: 'Elrejtés',
    columnMenuUnsort: 'Rendezetlen',
    columnMenuSortAsc: 'Növekvő sorbarendezés',
    columnMenuSortDesc: 'Csökkenő sorbarendezés',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`,
    columnHeaderFiltersLabel: 'Szűrők megjelenítése',
    columnHeaderSortIconLabel: 'Rendezés',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} sor kiválasztva`
            : `${count.toLocaleString()} sor kiválasztva`,

    // Total rows footer text
    footerTotalRows: 'Összes sor:',
};

export default GRID_DEFAULT_LOCALE_TEXT;
