import BaseAxiosService from './base-axios-service';
import {USER_SERVICE} from './constants/service-constants';

export default class AuthService extends BaseAxiosService {
    constructor() {
        super(USER_SERVICE);
    }

    static isAuthenticated() {
        return !!localStorage.getItem('login_completed');
    }

    login(data) {
        return this.postRequestWithBasicHeader('auth/login',data);
    }

    verify2fa(data) {
        return this.postRequest('auth/verify_2fa', data);
    }

    loginWithVerifyCode(data) {
        return this.postRequestWithBasicHeader('auth/login', data);
    }
}
