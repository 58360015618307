import React, {useEffect, useState} from 'react';
import MessagesService from 'services/messages-service';
import {useLocation} from 'react-router-dom';
import getMessageFolderByPath from 'utils/messages';
import getColumnsByFolderId from './columns';
import {usePromiseTracker} from 'react-promise-tracker';
import {MESSAGE_SERVICE} from 'services/constants/service-constants';
import BaseDataGrid from 'components/DataGrid/BaseDataGrid';
import SplitPane from 'react-split-pane';
import MessagesDetailsPage from '../View';
import MassActions from 'pages/Messages/List/components/MassActions';

const messageService = new MessagesService();

const MessagesListPage = () => {
    const [messages, setMessages] = useState([]);
    const [total, setTotal] = useState(0);
    const [listLoaded, setListLoaded] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);
    const [messageId, setMessageId] = useState(0);
    const [pagination,setPagination] = useState({page: 1, pageSize: 10});
    const location = useLocation();
    const folderId = getMessageFolderByPath(location.pathname);
    const {promiseInProgress: loading} = usePromiseTracker({area: MESSAGE_SERVICE});

    const fetchMessages = (filter) => {
        messageService.fetchMessages(folderId, filter)
            .then(res => {
                setMessages(res.data.result || []);
                setTotal(res.data.total || 0);
                setListLoaded(true);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (selectedRows.length===0 ) {
            let firstMessageId = messages[0]?.ID || 0;
            if (firstMessageId > 0) {
                setInitialSelectedRows([firstMessageId]);
            }
        }
    }, [messages]);

    const handleSelectedRows = (data) => {
        const {selectionModel} = data;
        setSelectedRows(selectionModel);
        if (selectionModel.length > 0) {
            if (selectionModel.length === 1) {
                setMessageId(parseInt(selectionModel[0]));
            }
        }
    };

    const columns = getColumnsByFolderId(folderId);

    const getSplitPos = (ix) => {
        let s = localStorage.getItem('splitPos')?.split(',') || [40, 60];
        return parseFloat(s[ix]).toString() + '%';
    };

    return (
        <>
            <MassActions
                selectedRows={selectedRows}
                fetchDataSource={fetchMessages}
                pagination={pagination}
            />
            <SplitPane
                split="vertical"
                minSize={50}
                // defaultSize={localStorage.getItem('splitPos')}
                onChange={(size) => {
                    localStorage.setItem('splitPos', size.toString());
                }}
            >
                <div style={{marginRight: '5px'}} initialSize={getSplitPos(0)} minSize="20%">
                    <BaseDataGrid
                        fetchDataSource={fetchMessages}
                        columns={columns}
                        rows={messages}
                        loading={loading}
                        handleSelectedRows={handleSelectedRows}
                        pageSize={pagination.pageSize}
                        total={total}
                        selectedRows={selectedRows}
                        selectionModel={initialSelectedRows}
                        setPagination={setPagination}
                    />
                </div>

                <div style={{marginLeft: '0px'}} initialSize={getSplitPos(1)} minSize="20%">
                    <MessagesDetailsPage
                        messagesListLoaded={listLoaded}
                        messageId={messageId}
                        selectedRows={selectedRows} />
                </div>
            </SplitPane>
        </>
    );
};

export default MessagesListPage;
