import React, {useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {useFormContext} from 'react-hook-form';
import {Tooltip, Typography} from '@material-ui/core';
import {formatUserObjectAsText} from 'utils/common';
import {ChevronRight, ChevronsRight, ChevronLeft, ChevronsLeft} from 'react-feather';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 300,
        height: 350,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    listRoot: {
        paddingBottom: 0,
        paddingTop: 0,
        // paddingRight: '0px',
        '& .MuiListItem-root': {
            padding: '0  10px 0 0',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '40px',
        },
        '& .MuiListItemText-root span': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    }
}));

const TransferUserSelect = ({users, members}) => {
    const classes = useStyles();
    const selectedRows = React.useRef([]);

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [checked, setChecked] = React.useState([]);
    const [leftUsers, setLeftUsers] = React.useState(users);
    const [rightUsers, setRightUsers] = React.useState([]);
    const [moveUsers, setMoveUsers] = React.useState(null);
    const [leftSelected, setLeftSelected] = React.useState([]);
    const [rightSelected, setRightSelected] = React.useState([]);
    const {setValue, clearErrors} = useFormContext();

    useEffect(() => {
        if (leftUsers.length) return;
        if (members.length > 0) {
            setLeftUsers(users.filter(row=>members.findIndex(user => user.ID === row.ID) === -1));
            setRightUsers(members);
        } else {
            setLeftUsers(users);
        }
    }, [users, members]);


    useEffect(() => {

        setValue('MEMBERS', rightUsers);

        if (rightUsers.length) {
            clearErrors('MEMBERS');
        }

    }, [rightUsers]);

    useEffect(() => {

        if (moveUsers === 'right') {
            setLeftUsers(
                users.filter(row => {
                    return (rightUsers.findIndex(user => user.ID === row.ID) === -1);
                })
            );
            setLeftSelected([]);
        }

        if (moveUsers === 'left') {
            setRightUsers(
                users.filter(row => {
                    return (leftUsers.findIndex(user => user.ID === row.ID) === -1);
                })
            );
            setRightSelected([]);
        }

        setMoveUsers(null);

    }, [moveUsers]);


    const moveSelectedToRight = () => {
        setRightUsers(
            rightUsers.concat(
                users.filter(row => leftSelected.map(Number).includes(row.ID))
            )
        );
        setMoveUsers('right');
    };

    const moveSelectedToLeft = () => {
        setLeftUsers(
            leftUsers.concat(
                users.filter(row => rightSelected.map(Number).includes(row.ID))
            )
        );
        setMoveUsers('left');
    };

    const moveAllToRight = () => {
        setRightUsers(
            rightUsers.concat(leftUsers)
        );
        setMoveUsers('right');
    };

    const moveAllToLeft = () => {
        setLeftUsers(
            leftUsers.concat(rightUsers)
        );
        setMoveUsers('left');
    };

    const handleSelected = (side, selectedUser) => () => {
        if (side === 'left') {
            if (leftSelected.findIndex(row => row === selectedUser.ID) === -1) {
                setLeftSelected(leftSelected.concat([selectedUser.ID]));
            } else {
                setLeftSelected(leftSelected.filter(row => row !== selectedUser.ID));
            }
        }
        if (side === 'right') {
            if (rightSelected.findIndex(row => row === selectedUser.ID) === -1) {
                setRightSelected(rightSelected.concat([selectedUser.ID]));
            } else {
                setRightSelected(rightSelected.filter(row => row !== selectedUser.ID));
            }
        }
    };

    const customList = (side, users, listName) => {

        return (
            <>
                <Typography style={{textAlign: 'center', marginBottom: 10}} variant="h5">{listName}</Typography>
                <Paper className={classes.paper}>

                    <List dense component="div" role="list" className={classes.listRoot}>
                        {users.map((user) => {
                            const labelId = `transfer-list-item-${user.ID}-label`;

                            return (
                                <ListItem
                                    key={user.ID}
                                    role="listitem"
                                    button
                                    onClick={handleSelected(side, user)}
                                    disableRipple={true}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={
                                                (side === 'left')
                                                    ? (leftSelected.findIndex(row => row === user.ID) !== -1)
                                                    : (rightSelected.findIndex(row => row === user.ID) !== -1)
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={formatUserObjectAsText(user.USER_OBJECT)}/>
                                </ListItem>
                            );
                        })}
                        <ListItem/>
                    </List>
                </Paper>
            </>
        );
    };

    return (
        <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
                {customList('left', leftUsers, 'Címjegyzék')}
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <IconButton
                        size="small"
                        className={classes.button}
                        onClick={moveAllToRight}
                        disabled={leftUsers.length === 0}
                        aria-label="move all right"
                    >
                        <ChevronsRight/>
                    </IconButton>
                    <IconButton
                        size="small"
                        className={classes.button}
                        onClick={moveSelectedToRight}
                        disabled={leftSelected.length === 0}
                        aria-label="move selected right"
                    >
                        <ChevronRight/>
                    </IconButton>
                    <IconButton
                        size="small"
                        className={classes.button}
                        onClick={moveSelectedToLeft}
                        disabled={rightSelected.length === 0}
                        aria-label="move selected left"
                    >
                        <ChevronLeft/>
                    </IconButton>
                    <IconButton
                        size="small"
                        className={classes.button}
                        onClick={moveAllToLeft}
                        disabled={rightUsers.length === 0}
                        aria-label="move all left"
                    >
                        <ChevronsLeft/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item>
                {customList('right', rightUsers, 'Csoport tagok')}
            </Grid>
        </Grid>
    );
};

export default TransferUserSelect;
