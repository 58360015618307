import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    progressBar: {
        margin: theme.spacing(3, 2, 1),
    }
}));

const InfoCard = ({className, config, ...rest}) => {

    const classes = useStyles();

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                {(config.icon) &&
                <Box
                    display="flex"
                    justifyContent="center"
                    mb={3}
                >
                    <Avatar
                        className={clsx(classes.iconWrapper, config.iconWrapperClass)}>
                        {config.icon}
                    </Avatar>
                </Box>
                }
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                >
                    {config.title}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    {config.description}
                </Typography>

                {(config.progress) &&
                <Box
                    display="flex"
                    justifyContent="center"
                    className={classes.progressBar}
                >
                    {config.progress}
                </Box>
                }

            </CardContent>
        </Card>
    );
};

InfoCard.propTypes = {
    className: PropTypes.string,
    config: PropTypes.object.isRequired,
    progress: PropTypes.object,
};

export default InfoCard;
