import {makeStyles} from '@material-ui/styles';

const messageFormStyle = makeStyles((theme) => ({
    form: {
        width: '100%',
        margin: 'auto',
        marginTop: theme.spacing(1),
    },
    field: {
        // marginBottom: theme.spacing(2)
        margin: theme.spacing(1, 0)
    },
    chips: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '10px',
        marginRight: '5px',
        '& .MuiChip-deleteIcon': {
            color: '#ffffff60',
        },
        '& .MuiChip-deleteIcon:hover': {
            color: 'white',
        }
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210,
        // marginBottom: theme.spacing(2)
    },
    dropzone: {
        outline: 'none',
        margin: theme.spacing(2, 0, 1, 0),
        borderStyle: 'dashed',
        borderColor: '#ccc',
        borderRadius: 4,
        borderWidth: 2,
        // backgroundColor: '#fff',
        height: 150,
        flex:1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f8f8',
        cursor: 'copy',
    },
    dropzoneInputLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: theme.fontFamily,
        color: theme.palette.primary.main,
        fontWeight: '400',
    },
    dropzoneParagraph: {
        fontFamily: theme.fontFamily,
        color: theme.palette.text.secondary,
        fontWeight: '400',
    },
    dropzonePreview: {
        marginTop: 10,
    },
    listItemIcon: {
        minWidth: '30px'
    },
    switch: {
        marginLeft: '0px'
    }
}));

export default messageFormStyle;
