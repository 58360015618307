import React, {useEffect, useRef, useState} from 'react';
import GroupEditForm from './components/GroupEditForm';
import GroupService from 'services/group-service';
import {parseApiError} from 'services/utils';
import formStyle from 'components/Layout/Styles/FormStyle';
import {Card, CardContent, CardHeader, Dialog, Modal} from '@material-ui/core';
import SpinnerLoader from 'components/Spinner/Spinner';
import {useSnackbar} from 'notistack';

const groupService = new GroupService();

const GroupEditPage = ({open, handleClose, dataId, setDataId}) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const classes = formStyle();
    const errorRef = useRef(false);

    useEffect(() => {
        if (dataId) {
            setLoading(true);
            groupService.fetchGroupData(dataId)
                .then(res => {
                    setData(res.data.data);
                    setLoading(false);
                });
        }
    }, [dataId]);

    const _onClose = () => {
        setDataId(0);
        setData(false);
        handleClose();
    };

    const handleSubmit = (jsonParams) => {
        if (data) {
            // groupService.updateGroup(jsonParams, dataId)
            //     .then(() => {
            //
            //     });
            return;
        }
        console.log(jsonParams);
        groupService.createGroup(
            jsonParams
        ).then(createGroupResult => {

            const {error, success} = createGroupResult.data;

            if (error) {
                let error = parseApiError(createGroupResult.data);
                setLoading(false);
                errorRef.current('apiError', {message: error.message.map((e) => e.val).join('\n')});
                return false;
            }

            if (success) {

                groupService.createGroupMember({
                    'GROUP_ID': createGroupResult.data.data_id,
                    'USER_ID': jsonParams.MEMBERS.map(r=>r.USER_ID),
                }).then(createGroupMemberResult => {

                    const {error, success} = createGroupMemberResult.data;

                    if (error) {
                        let error = parseApiError(createGroupMemberResult.data);
                        setLoading(false);
                        errorRef.current('apiError', {message: error.message.map((e) => e.val).join('\n')});
                        return false;
                    }

                    if (success) {
                        enqueueSnackbar('Az adatok mentése sikeresen megtörtént.', {variant: 'success'});
                        _onClose();
                    }

                });

            }

        });
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
        >
            <Card className={classes.dialogCard}>
                <CardHeader
                    className={classes.dialogCardHeader}
                    title={data ? 'Csoport szerkesztése' : 'Csoport létrehozása'}
                    titleTypographyProps={{fontSize: 20}}
                >
                </CardHeader>
                <CardContent>
                    {(loading)
                        ? <SpinnerLoader/>
                        : <GroupEditForm
                            submit={handleSubmit}
                            cancel={_onClose}
                            data={data}
                            classes={classes}
                            parentRef={errorRef}
                        />
                    }
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default GroupEditPage;
