import {makeStyles} from '@material-ui/styles';

const formStyle = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    card: {
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: theme.spacing(3, 0, 3),
        boxShadow: 'none',
    },
    cardHeader: {},
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    dialogCard: {
        padding: theme.spacing(1),
    },
    dialogCardHeader: {
        paddingBottom: 0
    },
    field: {
        marginBottom: theme.spacing(1)
    },
    nullSpacingField: {
        marginBottom: theme.spacing(0)
    },
    cancelButton: {
        backgroundColor: '#fff',
        color: '#bebebe',
        borderColor: 'transparent',
        marginRight: '5px',
        '&:hover':{
            backgroundColor: '#fff',
            color: '#8a8a8a',
            boxShadow: 0,
            borderColor: 'transparent',
        }
    },
    submitButtonWrapper : {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    submitCancelButtonWrapper : {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1)
    }
}));

export default formStyle;
