import React from 'react';
import AuthProvider from './AuthContext';
import ApiHealthProvider from './ApiHealth';

const CombinedContext = ({children}) => (
    <AuthProvider>
        <ApiHealthProvider>
            {children}
        </ApiHealthProvider>
    </AuthProvider>
);

export default CombinedContext;
