import {matchPath} from 'react-router-dom';
import get from 'lodash/get';

const findInSubRoutes = (route,path) =>
    get(route, 'subRoutes', []).find(subRoute => {
        const match = !!matchPath(path,subRoute);
        if (!match && subRoute.subRoutes) {
            return findInSubRoutes(subRoute, path);
        }
        return match;
    });

const flattenSubroutes = (route) => route.subRoutes.reduce(
    (paths,current) => {
        if (current.subRoutes) {
            paths.push(...flattenSubroutes(current));
            return paths;
        }

        paths.push(current);
        return paths;
    },
    [route]
);

export const getRouteArray = (routes) => routes.map(route => {
    if (route.subRoutes) {
        return flattenSubroutes(route);
    }
    return [route];
});

export const getRouteArrayByPath = (
    routes,
    path
) => {
    let arrayPaths = [];
    const array = getRouteArray(routes);
    array.some(routePaths => {
        if (routePaths.find(routePath => !!matchPath(path,routePath))) {
            arrayPaths = routePaths;
            return true;
        }

        return false;
    });
    return arrayPaths.filter(route => !!matchPath(path,route) || findInSubRoutes(route,path));
};
