import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import AuthService from '../services/auth-service';
import {AuthContext} from 'components/Context/AuthContext';

const RouteComponent = ({
    protectedRoute = false,
    publicRoute = false,
    component: Component,
    ...rest
}) => {
    const {logInCompleted} = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={props => {
                if (!AuthService.isAuthenticated() && logInCompleted) {
                    return <Redirect to="/messages/incoming"/>;
                }

                if (!logInCompleted && !protectedRoute && !rest.notFound) {
                    return <Component {...props} />;
                }

                if (logInCompleted && rest.notFound) {
                    return <Redirect to="/messages/incoming"/>;
                }

                if (logInCompleted && protectedRoute) {
                    return <Component {...props}/>;
                }

                return <Redirect to="/auth/login"/>;
            }}
        />
    );
};

export default RouteComponent;
