import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import {MenuItem, IconButton, Tooltip} from '@material-ui/core';
import { useHistory } from 'react-router-dom';


const TopMenu = ({route,classes}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const history = useHistory();

    const handleClick = (event) => {
        console.log(event);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getMenuList = route => route.subRoutes.map((subRoute,idx) =>
        <Link to={subRoute.path} key={subRoute.path} style={{textDecoration: 'none', color: 'inherit'}}>
            <MenuItem onClick={handleClose} key={idx}>{subRoute.label}</MenuItem>
        </Link>
    );

    return (
        <div>
            <Tooltip title={route.label} arrow>
                <IconButton
                    aria-controls="simple-menu"
                    onClick={(e)=>{
                        if (route.subRoutes) {
                            return handleClick(e);
                        } else {
                            history.push(route.path);
                        }
                    }}>
                    {route.Icon && <route.Icon color={'white'} />}
                </IconButton>
            </Tooltip>
            {(route.subRoutes) &&
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {getMenuList(route)}
            </Menu>
            }
        </div>
    );
};

export default TopMenu;
