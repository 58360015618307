import React from 'react';
import {BrowserRouter as Router, Route, withRouter} from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';
import LoginForm from 'pages/Authentication/components/Login';
import VerifyPhoneNumber from 'pages/Authentication/components/VerifyPhoneNumber';
import Verify2FACode from 'pages/Authentication/components/Verify2FACode';

createStore({});

const Login = () => {
    return (
        <StateMachineProvider>
            <Router>
                <Route path="/auth/login" exact={true} component={LoginForm} />
                <Route path="/auth/verifyphonenumber" component={VerifyPhoneNumber} />
                <Route path="/auth/verify2facode" component={Verify2FACode} />
            </Router>
        </StateMachineProvider>
    );
};

export default withRouter(Login);
