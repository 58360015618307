import {makeStyles} from '@material-ui/styles';

const messageDetailsStyle = makeStyles((theme) => ({
    card: {
        height: 'calc(100vh - 275px)',
        width: '100%',
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0),
        boxShadow: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f4f4f4',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0,
        overflow: 'auto'
    },
    cardHeader: {
        marginTop: 10,
        paddingBottom: 0,
        '& .MuiCardHeader-subheader': {
            color: '#c0c0c0',
        }
    },
    innerHeader: {
        margin: theme.spacing(0, 0, 1, 0)
    },
    tableCell: {
        padding: '10px 16px',
    },
    tableRowBordered: {
        borderLeft: '1px solid #e5e5e5',
        borderTop: '1px solid #e5e5e5',
        // backgroundColor: '#5fb3ec15',
    },
    tableCellBordered: {
        padding: '10px 16px',
        borderRight: '1px solid #e5e5e5',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
    },
    messageDetails: {
        padding: theme.spacing(2),
    },
    messageAttachments: {

    },
    button: {
        margin: theme.spacing(0),
        padding: '0px 5px',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    buttonDownload: {
        color: theme.palette.primary.main
    },
    emptyMessageCardContent: {
        height: 'calc(100% - 40px)',
        overflow: 'hidden',
    },
    emptyMessage: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'relative',
        '& svg': {
            alignItems: 'center',
        },
        '& .empty-0': {
            fill: '#bec7d0',
        },
        '& .empty-1': {
            fill: '#e8e8ea',
        },
    },
    emptyLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default messageDetailsStyle;
