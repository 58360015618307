import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, TextField} from '@material-ui/core';
import ValidationError from 'components/ValidationError/ValidationError';
import {LoadingButton} from '@material-ui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import ValidationSchema from 'pages/Recipients/Edit/components/recipient-validation';
import get from 'lodash/get';

const RecipientForm = ({submit, cancel, loading, data, classes}) => {
    const {handleSubmit, errors, control} = useForm({
        resolver: yupResolver(ValidationSchema),
        shouldFocusError: true,
        defaultValues: {
            LAST_NAME: get(data, 'USER_OBJECT.LAST_NAME', undefined),
            FIRST_NAME: get(data, 'USER_OBJECT.FIRST_NAME', undefined),
            EMAIL: get(data, 'USER_OBJECT.EMAIL', undefined),
        }
    });

    return (
        <form noValidate onSubmit={handleSubmit(submit)}>

            <Controller
                as={TextField}
                variant="outlined"
                margin="normal"
                required
                id="email"
                name="EMAIL"
                label="E-mail cím"
                control={control}
                InputProps={{
                    readOnly: (data) ? true : false,
                    disabled: (data) ? true : false,
                    style: {backgroundColor: (data) ? '#f6f6f6' :'',}
                }}
                fullWidth
                autoFocus={(data) ? false : true}
                defaultValue=""
                className={classes.nullSpacingField}
            />
            {errors.EMAIL && <ValidationError message={errors.EMAIL.message}/>}

            <Box display="flex">
                <Box flex="1" style={{marginRight: 5}}>
                    <Controller
                        as={TextField}
                        variant="outlined"
                        margin="normal"
                        required
                        id="last_name"
                        name="LAST_NAME"
                        label="Vezetéknév"
                        control={control}
                        InputProps={{}}
                        fullWidth
                        defaultValue=""
                        autoFocus={(data) ? true : false}
                        className={classes.nullSpacingField}
                    />
                    {errors.LAST_NAME && <ValidationError message={errors.LAST_NAME.message}/>}
                </Box>
                <Box flex="1" style={{marginLeft: 5}}>
                    <Controller
                        as={TextField}
                        variant="outlined"
                        margin="normal"
                        required
                        id="first_name"
                        name="FIRST_NAME"
                        label="Keresztnév"
                        control={control}
                        InputProps={{}}
                        fullWidth
                        defaultValue=""
                        className={classes.nullSpacingField}
                    />
                    {errors.FIRST_NAME && <ValidationError message={errors.FIRST_NAME.message}/>}
                </Box>
            </Box>

            <Box display="flex" justifyContent="flex-end" style={{marginTop: 10}}>
                <Button
                    type="button"
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={cancel}
                >
                    Mégsem
                </Button>
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.nullSpacingField}
                    pending={loading}
                >
                    Mentés
                </LoadingButton>
            </Box>

        </form>
    );
};

export default RecipientForm;
