import React from 'react';
import {BrowserRouter as Router, Route, withRouter} from 'react-router-dom';
import { StateMachineProvider, createStore } from 'little-state-machine';
import SignupForm from 'pages/Registration/components/Signup';
import VerifyPhoneNumber from 'pages/Authentication/components/VerifyPhoneNumber';
import Verify2FACode from 'pages/Authentication/components/Verify2FACode';

createStore({});

const Signup = () => {
    return (
        <StateMachineProvider>
            <Router>
                <Route path="/users/signup/:hash" exact={true} component={SignupForm} />
                <Route path="/users/signup/:hash/verifyphonenumber" component={VerifyPhoneNumber} />
                <Route path="/users/signup/:hash/verify2facode" component={Verify2FACode} />
            </Router>
        </StateMachineProvider>
    );
};

export default withRouter(Signup);
