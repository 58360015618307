import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {TablePagination} from '@material-ui/core';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import SpinnerLoader from '../Spinner/Spinner';
import EmptyRow from 'components/Table/components/EmptyRow';

const useStyles = makeStyles({
    container: {
        // maxHeight: 500,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: 0,
        boxShadow: 'none',
        backgroundColor: 'white',
        height: 'calc(100vh - 330px)',
        width: '100%',
    },
    tableRow: {
        // height: 40
    },
    tableCell: {
        padding: '10px 16px',
    },
    tableHeader: {},
    tableHeaderCell: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        padding: '10px 16px',
    },
    tablePagination: {
        backgroundColor: 'white',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
});

const BasicTable = (
    {
        columns,
        fetchDataSource,
        dataSource,
        ActionButtons,
        handleDetails,
        handleDelete,
        handleMove,
        loading,
        onDoubleClickHandle,
        handleOpen,
        setDataId,
        total
    }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRows(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchDataSource({page: page + 1, pageSize: rows});
    }, [page, rows]);

    const getData = (data, columnKey) => {
        if (isArray(columnKey)) {
            return columnKey.map(colKey => get(data, colKey) + ' ');
        }

        return get(data, columnKey);
    };

    const dataCells = (data) => columnKeys.map((columnKey, idx) => {
        const dataObject = getData(data, columnKey);
        if (dataObject) {
            return <TableCell align="left" key={idx} className={classes.tableCell}>{dataObject}</TableCell>;
        }

        if (columnKey === 'actionButtons' && ActionButtons) {
            return <TableCell align="left" key={idx}>
                <ActionButtons
                    data={data}
                    handleDetails={handleDetails}
                    handleDelete={handleDelete}
                    handleMove={handleMove}
                    handleOpen={handleOpen}
                    setDataId={setDataId}
                />
            </TableCell>;
        }

        return <TableCell align="left" key={idx} className={classes.tableCell}>&nbsp;</TableCell>;

    });

    const columnKeys = columns.map(column => column['key']);

    const getTableRows = (datas) => {
        if (datas.length === 0) {
            return <EmptyRow columnsLength={columns.length}/>;
        }

        return datas.map((data, idx) => (
            <TableRow
                key={idx}
                hover={true}
                className={classes.tableRow}
                onDoubleClick={() => {
                    if (onDoubleClickHandle) {
                        onDoubleClickHandle(data.ID);
                    }
                }}
                style={{cursor: onDoubleClickHandle ? 'pointer' : ''}}
            >
                {dataCells(data)}
            </TableRow>
        ));
    };

    if (loading) {
        return <SpinnerLoader/>;
    }

    return (
        <Paper>
            <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader className={classes.table} aria-label="sticky table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            {columns.map((column, idx) => <TableCell align="left" key={idx} width={column.columnWidth}
                                className={classes.tableHeaderCell}>{column.title}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTableRows(dataSource)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                className={classes.tablePagination}
                component="div"
                count={total}
                rowsPerPage={rows}
                rowsPerPageOptions={[10, 25, 50]}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Sorok száma'}
            />
        </Paper>
    );
};

export default BasicTable;
