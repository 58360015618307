import React, {useContext} from 'react';
import PageContent from './components/PageContent';
import Drawer from '@material-ui/core/Drawer';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import {AuthContext} from '../Context/AuthContext';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import layoutStyle from './Styles/LayoutStyle';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';


const MainLayout = ({children}) => {
    const classes = layoutStyle();
    const {logInCompleted} = useContext(AuthContext);
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <StyledEngineProvider injectFirst>

                <div className={classes.root}>
                    <CssBaseline/>

                    {(logInCompleted)
                        ?
                        <>
                            <Header classes={classes} handleDrawerOpen={handleDrawerOpen} open={open}/>
                            <SideMenu classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
                            <div className={classes.wrapper}>
                                <div className={classes.contentContainer}>
                                    <div className={classes.content}>
                                        <PageContent content={children} className={classes.pageContent}/>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className={classes.content}>
                            {children}
                        </div>
                    }
                </div>

            </StyledEngineProvider>
        </>
    );
};

export default MainLayout;
