import React, {useContext, useState} from 'react';
import {useStateMachine} from 'little-state-machine';
import updateAction from 'pages/Authentication/actions/updateAction';
import FormWrapper from 'pages/Authentication/components/FormWrapper';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import ValidationError from 'components/ValidationError/ValidationError';
import authStyle from 'pages/Authentication/Styles/AuthStyle';
import {useForm} from 'react-hook-form';
import AuthService from 'services/auth-service';
import {AuthContext} from 'components/Context/AuthContext';
import {useSnackbar} from 'notistack';
import {parseApiError} from 'services/utils';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import ValidationSchema from './code-validation';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Divider } from '@material-ui/core';
import Countdown, {zeroPad} from 'react-countdown';

const authService = new AuthService();

const Verify2FACode = () => {
    const history = useHistory();
    const {changeAccessToken, changeRefreshToken, updateLogInCompleted} = useContext(AuthContext);
    const classes = authStyle();
    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(ValidationSchema),
        shouldFocusError: true,
        defaultValues: {}
    });

    const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
    const [codeExpired, setCodeExpired] = useState(null);
    const [codeValidTime, setCodeValidTime] = useState(Date.now() + 90000);
    const {enqueueSnackbar} = useSnackbar();
    const {state} = useStateMachine(updateAction);

    const onSubmit = (data) => {
        authService.loginWithVerifyCode({...state, ...data})
            .then(res => {
                const {data} = res;
                if (data.success === true) {
                    changeAccessToken(data.msg.jwt_access_token);
                    changeRefreshToken(data.msg.jwt_refresh_token);
                    updateLogInCompleted(true);
                    history.push('/messages/incoming');
                    enqueueSnackbar('Sikeres bejelentkezés!', {variant: 'success'});
                } else {
                    let error = parseApiError(data);
                    enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                }
            });
    };

    const renderTime = ({ total, days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <div className="timer">&nbsp;</div>;
        }

        return (
            <div className={classes.timerTimer}>
                <div className={classes.timerValue}>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </div>
            </div>
        );
    };


    const sendNewCode = () => {
        setCodeExpired(false);
        setCodeValidTime(Date.now() + 90500);
        setInterval(()=>{
            setCurrentTimeIndex(currentTimeIndex + 1);
        }, 1000);
    };

    return (
        <FormWrapper>

            <Typography component="p" className={classes.infoText}>
                Kérjük add meg a biztonsági ellenőrző kódot. A kódot SMS üzenetben küldtük az általad megadott telefonszámra. A kód megadására 90 másodperced van.
            </Typography>

            <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="VERIFY_CODE"
                    label="Ellenőrző kód"
                    name="VERIFY_CODE"
                    autoFocus
                    inputRef={register}
                    defaultValue={state.VERIFY_CODE}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                        endAdornment: <Countdown
                            key={currentTimeIndex}
                            date={codeValidTime}
                            intervalDelay={0}
                            precision={0}
                            renderer={renderTime}
                            onComplete={()=>{setCodeExpired(true);}}
                        />
                    }}

                />
                {errors.VERIFY_CODE && <ValidationError message={errors.VERIFY_CODE.message}/>}

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Button
                            type="button"
                            fullWidth
                            size="large"
                            className={classes.cancelButton}
                            onClick={()=>{history.push('./login');}}
                        >
                            Mégsem
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.form}
                            disabled={(codeExpired)}
                        >
                            Kód ellenőrzése
                        </Button>
                    </Grid>

                    <Grid item xs={12} hidden={!codeExpired}>
                        <Button
                            type="button"
                            fullWidth
                            color="primary"
                            size="small"
                            className={classes.getNewCodeButton}
                            disabled={!codeExpired}
                            onClick={sendNewCode}
                        >
                            Új kód küldése
                        </Button>
                    </Grid>
                </Grid>
            </form>

        </FormWrapper>
    );
};

export default Verify2FACode;
