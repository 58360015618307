import BaseAxiosService from './base-axios-service';
import {MESSAGE_SERVICE} from './constants/service-constants';

export default class MessagesService extends BaseAxiosService {
    constructor() {
        super(MESSAGE_SERVICE);
    }

    fetchMessages(folderId,filter) {
        return this.getRequest(`q/folder_id:${folderId};page:${filter.page};limit:${filter.pageSize}`, 'messages');
    }

    downloadAttachment(attachmentId) {
        return this.getFile(`attachment/${attachmentId}`, 'messages');
    }

    createMessage(messageData) {
        return this.postRequest('',messageData);
    }

    createAttachment(files, messageId) {
        const formData = new FormData();
        formData.append('MESSAGE_ID', messageId);
        files.map((item, idx) => {
            formData.append(`ATTACHMENT_${idx}`, item);
        });
        return this.postRequest('attachment', formData);
    }

    createMessageDone(messageId) {
        return this.postRequest('done', {'MESSAGE_ID': messageId});
    }

    fetchMessageById(id) {
        return this.getRequest(`${id}`,null,'getMessage');
    }

    deleteMessageById(id) {
        return this.deleteRequest(`${id}`);
    }

    fetchMessageEvents(id) {
        return this.getRequest(`${id}/events`);
    }

    moveMessages(data) {
        return this.putRequest('folder', data);
    }

    deleteMessagesByIds(data) {
        return this.deleteRequest('', data);
    }
}
