import {makeStyles} from '@material-ui/styles';

const registrationStyle = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    card: {
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: theme.spacing(3, 0, 3),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    names: {
        display: 'flex',
    },
    nameInput1: {
        flex: 1,
        marginRight: '5px'
    },
    nameInput2: {
        flex: 1,
        marginLeft: '5px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    statusCard: {
        margin: theme.spacing(3, 0, 2),
        width: '100%'
    },
    iconWrapperClass: {
        backgroundColor: theme.palette.background.success,
    }
}));

export default registrationStyle;
