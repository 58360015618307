import {makeStyles} from '@material-ui/styles';

const dataGridStyle = makeStyles((theme) => ({
    gridContainer: {
        backgroundColor: 'white',
        // height: 600,
        height: 'calc(100vh - 275px)',
        width: '100%',
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-root': {
            borderColor: '#f4f4f4',
            borderRightWidth: 2,
            borderRightColor: '#e9e9e9',
            // borderWidth: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none',
        }
    },
}));

export default dataGridStyle;
