import React, {useEffect, useState} from 'react';
import ProfileEditForm from 'pages/Me/components/ProfileEditForm';
import {useLoadState} from 'utils/hooks';
import formStyle from 'components/Layout/Styles/FormStyle';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import UserService from 'services/user-service';
import {parseApiError} from 'services/utils';
import {useSnackbar} from 'notistack';

const userService = new UserService();

const ProfileSettingsPage = () => {
    const [user,setUser] = useState();
    const [loading, setLoading] = useState(false);
    const {load, setLoad} = useLoadState();
    const classes = formStyle();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setLoading(true);
        userService.fetchUserDataWithToken()
            .then(res => {
                setUser(res.data.data);
                setLoading(false);
            });
    }, []);

    const handleSubmit = data => {
        // TODO: api call for user edit
        console.log(data);
        setLoading(true);
        userService.updateUser(user.ID, data)
            .then(userServiceRes => {

                let res = userServiceRes.data;
                console.log(res);
                if (res.success === true) {
                    //
                    enqueueSnackbar('Az adatok módosítása sikeresen megtörtént!', {variant: 'success'});
                } else {
                    let error = parseApiError(res);
                    enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                }
                // setUser(res.data.data);
                setLoading(false);
            });
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title="Profil szerkesztése"
                subheader="Kérjük minden csillaggal megjelölt adatot adjon meg."
            >
            </CardHeader>
            <CardContent>
                <ProfileEditForm user={user} submit={handleSubmit} loading={loading} classes={classes}/>
            </CardContent>
        </Card>
    );
};

export default ProfileSettingsPage;
