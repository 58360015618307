import React from 'react';
import {
    Box, Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import {GridOverlay} from '@material-ui/x-grid';


const MessageContentEmpty = (
    {
        classes
    }) => {

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
            >
            </CardHeader>
            <CardContent className={classes.emptyMessageCardContent}>

                <Box className={classes.emptyMessage}>
                    <svg
                        width="260"
                        height="140"
                        viewBox="0 0 300 170"
                        aria-hidden
                        focusable="false"
                    >
                        <path
                            className="empty-0"
                            d="M210,141h-17.1l1.2-2c0.8-1.4,1.2-3,1.2-4.7V56.4c0-2.5-1-4.8-2.7-6.6c-1.7-1.7-4.1-2.7-6.6-2.7h-64  c-2.5,0-4.8,1-6.6,2.7c-1.8,1.8-2.7,4.1-2.7,6.6v4.1h-9.3c-2.5,0-4.8,1-6.6,2.7C95,65,94,67.3,94,69.8v77.7c0,1.6,0.4,3.2,1.2,4.7  l1.2,2H82c-0.7,0-1.3,0.6-1.3,1.3c0,0.3,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4h67.7l6.9,6.9c0.7,0.7,1.8,1.2,2.8,1.2  c1,0,2.1-0.4,2.8-1.2c1.3-1.3,1.5-3.3,0.6-4.9l-1.2-2.1H178c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3h-3.8l1.2-2  c0.8-1.4,1.2-3,1.2-4.7v-4.1h33.4c0.7,0,1.3-0.6,1.3-1.3C211.3,141.5,210.7,141,210,141L210,141z M174,147.6c0,3.7-3,6.7-6.7,6.7h-9  l-9.3-9.4c-1-1-2.5-1.4-3.8-1l-0.8,0.2l-3.9-3.9l0.7-0.9c5.4-7.3,4.3-17.6-2.5-23.6c-6.8-6-17.2-5.6-23.6,0.9  c-6.4,6.4-6.7,16.8-0.8,23.7c5.9,6.8,16.2,8,23.4,2.6l0.9-0.7l3.9,3.9l-0.2,0.8c-0.4,1.4,0,2.9,1,3.9l3.7,3.7h-43.7  c-3.7,0-6.7-3-6.7-6.7v-78c0-3.6,2.9-6.6,6.5-6.6h64.4c3.6,0,6.5,3,6.5,6.6C174,69.8,174,147.6,174,147.6z M137.7,139.3  c-2.9,2.9-6.6,4.3-10.4,4.3s-7.5-1.4-10.4-4.3c-2.8-2.8-4.3-6.5-4.3-10.4c0-3.9,1.5-7.7,4.3-10.4c2.8-2.8,6.5-4.3,10.4-4.3  c3.9,0,7.6,1.5,10.4,4.3c2.8,2.8,4.3,6.5,4.3,10.4C142,132.8,140.5,136.5,137.7,139.3z M192.7,134.2c0,3.7-3,6.7-6.7,6.7h-9.4v-71  c0-2.5-1-4.8-2.7-6.6c-1.7-1.7-4.1-2.7-6.6-2.7h-52v-4c0-3.7,3-6.7,6.7-6.7h64c3.7,0,6.7,3,6.7,6.7L192.7,134.2L192.7,134.2z   M71.2,154.4h-16c-0.7,0-1.3,0.6-1.3,1.3c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4h16c0.7,0,1.3-0.6,1.3-1.3  S72,154.4,71.2,154.4L71.2,154.4z"/>
                        <path
                            className="empty-1"
                            d="M111.3,76.6h29.3c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-29.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.3,0.1,0.7,0.4,0.9  C110.6,76.5,111,76.6,111.3,76.6L111.3,76.6z M154,87.4h-42.7c-0.7,0-1.3,0.6-1.3,1.3c0,0.4,0.1,0.7,0.4,0.9  c0.2,0.2,0.6,0.4,0.9,0.4H154c0.7,0,1.3-0.6,1.3-1.3C155.3,88,154.7,87.4,154,87.4z M130,100.8h-18.7c-0.7,0-1.3,0.6-1.3,1.3  c0,0.4,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4h18.7c0.7,0,1.3-0.6,1.3-1.3C131.2,101.3,130.7,100.8,130,100.8z M70,124.9h2.7  c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3H70v2.7c0,0.7-0.6,1.3-1.3,1.3c-0.4,0-0.7-0.1-0.9-0.4c-0.3-0.2-0.4-0.6-0.4-0.9v-2.7h-2.7  c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h2.7v-2.7c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3C70,122.2,70,124.9,70,124.9z   M246,114.1v-2.7c0-0.4-0.1-0.7-0.4-0.9s-0.6-0.4-0.9-0.4c-0.7,0-1.3,0.6-1.3,1.3v2.7h-2.7c-0.4,0-0.7,0.1-0.9,0.4  c-0.2,0.3-0.4,0.6-0.4,0.9c0,0.7,0.6,1.3,1.3,1.3h2.7v2.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-2.7h2.7c0.7,0,1.3-0.6,1.3-1.3  s-0.6-1.3-1.3-1.3L246,114.1L246,114.1z M84,41.1h4c1.1,0,2,0.9,2,2s-0.9,2-2,2h-4v4c0,1.1-0.9,2-2,2c-0.5,0-1-0.2-1.4-0.6  C80.2,50.1,80,49.6,80,49v-4h-4c-0.5,0-1-0.2-1.4-0.6c-0.4-0.3-0.6-0.8-0.6-1.4c0-1.1,0.9-2,2-2h4v-4c0-1.1,0.9-2,2-2s2,0.9,2,2  L84,41.1L84,41.1z M57.9,69.9c-2.9,0-5.5,1.5-6.9,4c-1.4,2.5-1.4,5.6,0,8c1.4,2.5,4.1,4,6.9,4c4.4,0,8-3.6,8-8S62.3,69.9,57.9,69.9z   M61.4,80c-0.7,1.2-2,2-3.5,2c-2.2,0-4-1.8-4-4s1.8-4,4-4c1.4,0,2.7,0.8,3.5,2C62.1,77.2,62.1,78.7,61.4,80z M223.3,53.9  c-2.9,0-5.5,1.5-6.9,4c-1.4,2.5-1.4,5.6,0,8c1.4,2.5,4.1,4,6.9,4c4.4,0,8-3.6,8-8C231.3,57.5,227.8,53.9,223.3,53.9z M226.8,63.9  c-0.7,1.2-2,2-3.5,2c-2.2,0-4-1.8-4-4s1.8-4,4-4c1.4,0,2.8,0.8,3.5,2C227.5,61.1,227.5,62.7,226.8,63.9z"/>
                    </svg>
                    <div className={classes.emptyLabel}>Nincs megjeleníthető adat...</div>
                </Box>


            </CardContent>
        </Card>
    );
};

export default MessageContentEmpty;
