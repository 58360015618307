import React, {useEffect, useState} from 'react';
import MessageEditForm from './components/MessageEditForm';
import MessagesService from 'services/messages-service';
import {useHistory, useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {parseApiError} from 'services/utils';
import {Card, CardContent, CardHeader, } from '@material-ui/core';
import formStyle from 'components/Layout/Styles/FormStyle';
import SpinnerLoader from 'components/Spinner/Spinner';

const messageService = new MessagesService();

const EditMessagePage = () => {
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const history = useHistory();
    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const classes = formStyle();
    const isReply = id !== undefined;

    useEffect(() => {
        if (id) {
            setLoading(true);
            messageService.fetchMessageById(id)
                .then(res => {
                    setMessage(res.data.data);
                    setLoading(false);
                });
        }
    }, [id]);

    const submit = data => {
        // console.log(data, files);

        if ( data.REPLY_TO === '' || typeof data.REPLY_TO === 'undefined' ) {
            delete data.REPLY_TO;
        }

        if ( data.IS_GROUP_MESSAGE === true ) {
            delete data.RECIPIENTS;
        } else {
            delete data.GROUP_IDS;
        }

        setLoading(true);

        messageService
            .createMessage(data)
            .then((message_resp) => {
                const {error, success} = message_resp.data;

                if (error) {
                    let error = parseApiError(message_resp.data);
                    enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                    setLoading(false);
                    return false;
                }

                if (success) {
                    const {message_id} = message_resp.data;

                    if (files.length) {
                        messageService
                            .createAttachment(files, message_id)
                            .then((message_attachment_resp) => {

                                const {error, success} = message_attachment_resp.data;

                                if (error) {
                                    let error = parseApiError(message_resp.data);
                                    enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                                    setLoading(false);
                                    return;
                                }

                                if (success) {
                                    messageService
                                        .createMessageDone(message_id)
                                        .then((message_done_resp) => {
                                            enqueueSnackbar('Az üzenet elküldése sikeresen megtörtént!', {variant: 'success'});
                                            history.push('/messages/incoming');
                                        });

                                }
                            })
                            .catch(err => {
                                enqueueSnackbar('Az üzenet küldése sikertelen!', {variant: 'error'});
                                setLoading(false);
                            });
                    }
                }
            });
    };

    const handleUpload = uploadFiles => {
        setFiles(uploadFiles);
    };

    const removeFileFromUpload = uploadFile => {
        setFiles(files.filter(file => file.path !== uploadFile.path));
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title="Üzenet szerkesztése"
                subheader="Kérjük minden csillaggal megjelölt adatot adjon meg."
            >
            </CardHeader>
            <CardContent>
                {(isReply && !message) ? <SpinnerLoader /> : <MessageEditForm
                    submit={submit}
                    handleUpload={handleUpload}
                    removeFileFromUpload={removeFileFromUpload}
                    message={message}
                    filesCount={files.length}
                    loading={loading}
                    isReply={isReply}
                />}
            </CardContent>
        </Card>
    );
};

export default EditMessagePage;
