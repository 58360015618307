import {makeStyles} from '@material-ui/styles';

const authStyle = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: theme.spacing(3, 0, 3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    cancelButton: {
        marginTop: theme.spacing(1),
        color: '#8f8f8f',
        '&:hover':{
            backgroundColor: '#fff',
            color: '#323232',
            boxShadow: 0,
            borderColor: 'transparent',
        }
    },
    getNewCodeButton: {
        color: theme.palette.primary.main,
        '&:hover':{
            backgroundColor: '#fff',
            // color: '#323232',
            boxShadow: 0,
            borderColor: 'transparent',
        }
    },
    infoText: {
        fontSize: '15px',
        color: '#616264',
        margin: theme.spacing(2, 0, 3),
        textAlign: 'justify',
        letterSpacing: '0.5px'
    },
    countDownWrapper: {
        // margin: theme.spacing(1, 0, 1),
    },
    timerExpired: {
        position: 'absolute',
        backgroundColor: '#F7B801',
        width: 50,
        height: 50,
        borderRadius: 50
    },
    timerWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    timerTimer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    timerValue: {
        fontSize: '15px',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    }
}));

export default authStyle;
