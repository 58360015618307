export const parseApiError = (e) => {

    let error = {
        message: []
    };

    if ('msg' in e) {
        let msg = e.msg;

        if ('apierror' in msg) {

            let _errorData = msg.apierror;

            /* eslint-disable */
            if (typeof _errorData === "object") {
                if (typeof _errorData.message === "object") {
                    _errorData.message.map((errorValue) => {
                        if (typeof errorValue === "object") {
                            error.message.push(errorValue[0]);
                        } else {
                            error.message.push({'id': 'apierror', 'val': errorValue});
                        }
                    });
                }else{
                    error.message.push({'id': 'apierror', 'val': _errorData.message});
                }
            } else {
                error.message.push(_errorData);
            }
            /* eslint-enable */

        }
    } else if ('error' in e) {
        error.message.push({'id': 'apierror', 'val': e.error});
    }

    return error;
};
