import React, {useContext, useEffect} from 'react';
import {AuthContext} from 'components/Context/AuthContext';
import {useHistory} from 'react-router-dom';

const LogoutPage = () => {
    const history = useHistory();
    const {changeAccessToken,changeRefreshToken,updateLogInCompleted} = useContext(AuthContext);
    useEffect(() => {
        changeAccessToken(null);
        changeRefreshToken(null);
        updateLogInCompleted(false);
    }, []);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('login_completed');

    history.push('/auth/login');

    return null;
};

export default LogoutPage;
