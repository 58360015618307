import * as yup from 'yup';

export default yup.object().shape({
    NAME: yup.string().required('A csoport név megadása kötelező!'),
    MEMBERS: yup.array()
        .min(1,'A tagok megadása kötelező!')
        .of(
            yup.object().shape({
                email: yup.string().email('Hibás e-mail címet adott meg!')
            })
        )
});