import BaseAxiosService from './base-axios-service';
import {USER_SERVICE} from './constants/service-constants';

export default class UserService extends BaseAxiosService {
    constructor() {
        super(USER_SERVICE);
    }

    fetchUserDataWithToken() {
        return this.getRequest('');
    }

    fetchUserDataWithHash(u_hash) {
        return this.getRequestWithBasicHeader('signup/' + u_hash);
    }

    createUser(data) {
        return this.postRequestWithBasicHeader('',
            {...data}
        );
    }

    updateUser(id, data) {
        return this.putRequest(`${id}`, {...data});
    }

    fetchAddressbookData(id) {
        return this.getRequest(`addressbook/${id}`);
    }

    fetchAddressbookWithoutParams() {
        return this.getRequest('addressbook');
    }

    fetchAddressbookAllItems() {
        return this.getRequest('addressbook/q/page:1;limit:-1;');
    }

    fetchAddressbook(filter) {
        if (!filter.page) {
            filter.page = 1;
        }
        if (!filter.limit) {
            filter.limit = 10;
        }
        return this.getRequest(`addressbook/q/page:${filter.page};limit:${filter.limit};q:${filter.q}`);
    }

    addUserToAddressbook(userData) {
        return this.postRequest('addressbook', userData);
    }

    changePassword(data) {
        return this.postRequest('changepasswd', data);
    }
}
