import React, {useState} from 'react';
import PasswordResetForm from 'pages/PasswordReset/components/PasswordResetForm';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import formStyle from 'components/Layout/Styles/FormStyle';
import UserService from 'services/user-service';
import {useSnackbar} from 'notistack';
import {parseApiError} from 'services/utils';

const userService = new UserService();

const PasswordReset = () => {
    const classes = formStyle();
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const submit = data => {
        setLoading(true);

        userService.changePassword(data)
            .then(res => {
                const {error, success} = res.data;

                if (success) {
                    enqueueSnackbar('Az jelszó módosítása sikeresen megtörtént!', {variant: 'success'});
                    setLoading(false);
                    return;
                }

                const parsedError = parseApiError(error);
                enqueueSnackbar(parsedError.message.map((e) => e.val).join('\n'), {variant: 'error'});
                setLoading(false);
            })
            .catch(() => {
                enqueueSnackbar('A jelszó módosítása sikertelen!', {variant: 'error'});
                setLoading(false);
            });
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title="Jelszó módosítás"
                subheader="Kérjük minden csillaggal megjelölt adatot adjon meg."
            >
            </CardHeader>
            <CardContent>
                <PasswordResetForm submit={submit} loading={loading} classes={classes}/>
            </CardContent>
        </Card>
    );
};

export default PasswordReset;
