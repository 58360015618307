import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {PlusCircle} from 'react-feather';
import massActionsStyle from 'pages/Messages/List/Styles/MassActionsStyle';

const MassActions = ({handleOpen}) => {
    const classes = massActionsStyle();

    return (
        <div className={classes.buttonContainer}>
            <IconButton
                className={classes.button}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
                onClick={() => handleOpen()}
            >
                <PlusCircle className={classes.buttonDetails} size={18}/>
            </IconButton>
        </div>
    );
};

export default MassActions;
