import React, {useEffect, useState} from 'react';
import {useForm, Controller, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Button, TextField} from '@material-ui/core';
import get from 'lodash/get';
import ValidationSchema from './group-validation';
import ValidationError from 'components/ValidationError/ValidationError';
import {LoadingButton} from '@material-ui/lab';
import UserService from 'services/user-service';
import TransferUserSelect from 'pages/Groups/Edit/components/TransferUserSelect';

const userService = new UserService();

const GroupEditForm = ({submit, cancel, data, loading, classes, parentRef}) => {
    const methods = useForm({
        resolver: yupResolver(ValidationSchema),
        shouldFocusError: true,
        defaultValues: {
            NAME: get(data, 'NAME', undefined),
            MEMBERS: [],
        }
    });
    const [users, setUsers] = useState([]);
    const [members, setMembers] = useState([]);

    const fetchUsers = () => {
        if (users.length) return;

        userService.fetchAddressbookAllItems()
            .then(res => {
                setUsers(res.data.result);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (data) {
            setMembers(data.MEMBERS);
        }
    }, [data]);

    parentRef.current = methods.setError;

    return (
        <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(submit)}>
                <Controller
                    as={TextField}
                    variant="outlined"
                    margin="normal"
                    required
                    id="name"
                    name="NAME"
                    label="Csoport neve"
                    control={methods.control}
                    defaultValue=""
                    fullWidth
                    className={classes.field}
                />
                {methods.errors.NAME && <ValidationError message={methods.errors.NAME.message}/>}
                {methods.errors.MEMBERS && <ValidationError message={methods.errors.MEMBERS.message}/>}
                {methods.errors.apiError && <ValidationError message={methods.errors.apiError.message}/>}
                <Controller
                    as={TextField}
                    id="members"
                    name="MEMBERS"
                    control={methods.control}
                    defaultValue=""
                    InputProps={{
                        readOnly: true,
                        type: 'hidden',
                        style: {display: 'none'}
                    }}
                />

                <div className={classes.submitCancelButtonWrapper}>
                    <Button
                        type="button"
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={cancel}
                    >
                        Mégsem
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.nullSpacingField}
                        pending={loading}
                    >
                        Mentés
                    </LoadingButton>
                </div>
                <TransferUserSelect users={users} members={members} />
            </form>
        </FormProvider>
    );
};

export default GroupEditForm;
