import React, {useCallback, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {
    UploadCloud as UploadCloudIcon,
    File as FileIcon,
    X as RemoveIcon,
} from 'react-feather';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

const FileUploadDropZone = (
    {
        classes,
        initialFiles,
        handleUpload,
        removeFileFromUpload,
    }) => {

    const theme = useTheme();
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {

    }, [selectedFiles]);

    const onDrop = useCallback((files) => {
        files.forEach((file) => {

        });
        handleUpload(files);
        setSelectedFiles(files);
    }, [selectedFiles]);

    const onDelete = useCallback((file) => {
        setSelectedFiles(selectedFiles.filter(f => f.path !== file.path));
        removeFileFromUpload(file);
    }, [selectedFiles]);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 20,
        onDrop: onDrop
    });

    const acceptedFileItems = selectedFiles.map(file => (
        <ListItem key={file.path}>
            <ListItemIcon className={classes.listItemIcon}>
                <FileIcon  size={18} />
            </ListItemIcon>
            <ListItemText id={file.path} primary={`${file.path}`}/>
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="Eltávolítás"
                    size="small"
                    onClick={(e => onDelete(file))}>
                    <RemoveIcon size={18} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ));

    const InputContent = () => {
        return (
            <Box className={classes.dropzoneInputLabel}>
                <Box>
                    <Typography>
                        Húzza ide a fájlokat...
                    </Typography>
                </Box>
                <Box>
                    <UploadCloudIcon/>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box {...getRootProps({className: classes.dropzone})}>
                <input {...getInputProps()} />
                <InputContent/>
            </Box>
            {selectedFiles.length > 0 && (
                <Box className={classes.dropzonePreview}>
                    <Typography>Hozzáadott fájlok</Typography>
                    <List dense className={classes.root}>
                        {acceptedFileItems}
                    </List>
                </Box>
            )}
        </>
    );

};

export default FileUploadDropZone;
