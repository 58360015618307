import React from 'react';
import {Avatar, Card, CardContent, Container, CssBaseline, Typography} from '@material-ui/core';
import {
    Lock as LockIcon,
} from 'react-feather';
import authStyle from 'pages/Authentication/Styles/AuthStyle';

const FormWrapper = ({children}) => {
    const classes = authStyle();
    return (
        <>
            {/*<Backdrop className={classes.backdrop} open={promiseInProgress}>*/}
            {/*    <CircularProgress color="inherit"/>*/}
            {/*</Backdrop>*/}
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h4">
                        Dunacorp Zrt.
                    </Typography>
                    <Typography component="p" variant="subtitle1" color="textSecondary">
                        Tárhely Bejelentkezés
                    </Typography>

                    <Card className={classes.card}>
                        <CardContent>
                            {children}
                        </CardContent>
                    </Card>
                </div>
            </Container>
        </>
    );
};

export default FormWrapper;