import React, {useEffect, useState} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import GRID_DEFAULT_LOCALE_TEXT from 'components/DataGrid/components/grid-locale-text';
import EmptyRow from 'components/DataGrid/components/EmptyRow';
import dataGridStyle from 'components/DataGrid/Styles/DataGridStyles';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';


const BaseDataGrid = ({
    rows,
    columns,
    fetchDataSource,
    loading,
    handleSelectedRows,
    total,
    pageSize = 10,
    selectionModel,
    setPagination
}) => {
    const classes = dataGridStyle();

    LicenseInfo.setLicenseKey(
        '0ae14373a5daf4cd685320cec8c571b0T1JERVI6MjMzODQsRVhQSVJZPTE2NDkzMzU2MDkwMDAsS0VZVkVSU0lPTj0x',
    );

    useEffect(() => {
        fetchDataSource({page: 1, pageSize: pageSize});
    },[]);

    const handlePageSizeChange = param => {
        fetchDataSource({page: param.page+1,pageSize: param.pageSize});
        if (setPagination) {
            setPagination({page: param.page+1,pageSize: param.pageSize});
        }
    };

    const handlePageChange = param => {
        fetchDataSource({page: param.page+1, pageSize: param.pageSize});
        if (setPagination) {
            setPagination({page: param.page+1,pageSize: param.pageSize});
        }
    };

    return (
        <div className={classes.gridContainer}>
            <XGrid
                getRowId={(row) => row.ID}
                autoHeight={false}
                showToolbar={true}
                columns={columns}
                columnTypes={rows}
                rows={rows}
                pageSize={pageSize}
                rowCount={total}
                checkboxSelection={false}
                disableMultipleSelection={false}
                density="standard"
                headerHeight={46}
                localeText={GRID_DEFAULT_LOCALE_TEXT}
                rowsPerPageOptions={[5,10,25,50,100]}
                loading={loading}
                onPageSizeChange={(param) => handlePageSizeChange(param)}
                onPageChange={(param) => handlePageChange(param)}
                pagination={true}
                paginationMode='server'
                selectionModel={selectionModel}
                components={{
                    NoRowsOverlay: EmptyRow
                }}
                disableColumnMenu={true}
                disableColumnSelector={true}
                showColumnRightBorder={false}
                disableColumnReorder={true}
                disableColumnResize={true}
                onSelectionModelChange={(rows) => handleSelectedRows(rows)}
                hideFooterSelectedRowCount={true}
            />
        </div>
    );
};

export default BaseDataGrid;
