import {createMuiTheme, colors} from '@material-ui/core';
import shadows from './Shadows';
import typography from './Typography';
import {adaptV4Theme} from '@material-ui/core/styles';
import { huHU } from '@material-ui/core/locale';

const theme = createMuiTheme(adaptV4Theme({
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    palette: {
        background: {
            dark: '#F7F9FC',
            default: colors.common.white,
            paper: colors.common.white,
            success: '#b3de68'
        },
        primary: {
            main: '#187bcd'
        },
        secondary: {
            main: '#1167b1'
        },
        danger: '#dc3545',
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
            listaction: colors.blueGrey[500],
            breadcrumb: colors.grey[500],
        }
    },
    shadows,
    typography,
    overrides: {
        MuiTableCell: {
            root: {
                padding: '0px 16px 0px 16px'
            },
        }
    },
}), huHU);

export default theme;
