import {makeStyles} from '@material-ui/styles';

const drawerWidth = 250;

const layoutStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'

    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        // [theme.breakpoints.up('lg')]: {
        //     paddingLeft: 256
        // }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        padding: 40
    },
    breadcrumbs: {
        '& h5': {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.primary.main,
        },
        '& svg,p': {
            // color: theme.palette.primary.main,
            color: theme.palette.text.breadcrumb,
        },
        '& li,a': {
            // color: 'red',
            // fontWeight: 300,
        },
        '& li:last-child h5': {
            color: theme.palette.text.breadcrumb,
        }
    },
    appBar: {
        backgroundColor: theme.palette.dark,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        top: 64,
        paddingBottom: '5rem'
    },
    drawerHeader: {
        // display: 'flex',
        position: 'absolute',
        right: 0,
        justifyContent: 'flex-end',
    },
    pageContent: {
        padding: theme.spacing(4),
    },
    headerParent: {

    },
    topMenuParent: {
        position: 'absolute',
        display: 'inherit',
        right: theme.spacing(3)
    },
    version: {
        color: theme.palette.primary.main,
        position: 'absolute',
        bottom: '75px',
        textAlign: 'center',
        left: '0px',
        right: '0px',
        fontSize: '10px'
    }
}));

export default layoutStyle;
