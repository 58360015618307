import React from 'react';
import {
    Box, Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import {Download as DownloadIcon} from 'react-feather';
import {formatUserObject} from 'utils/common';
import Skeleton from 'react-loading-skeleton';


const MessageContent = (
    {
        message,
        classes,
        onDownloadAttachment,
        downloading,
        loading
    }) => {

    const getMessageHeader = message => {

        let recipientsLength = message.RECIPIENTS.length;

        let recipientsFormatted = message.RECIPIENTS.map(r => {
            return formatUserObject(r);
        });

        return [{
            'label': 'Feladó',
            'value': formatUserObject(message.SENDER_OBJECT)
        }, {
            'label': (recipientsLength > 1) ? 'Címzettek' : 'Címzett',
            'value': recipientsFormatted.map((r, i) =>
                <>
                    {r}
                    {(recipientsLength > (i + 1)) && ', '}
                </>
            ),
        }];
    };

    const getMessageAttachments = message => {
        return message.ATTACHMENTS?.map(r => {
            return {
                'id': r.ID,
                'filename': r.NAME,
                'filesize': r.SIZE
            };
        }) || [];
    };

    // console.log('loading --> ', loading);

    return (
        <Card className={classes.card}>
            <CardHeader
                title={
                    (loading)
                        ? <Skeleton width={'35%'} style={{lineHeight: 1.6}}/>
                        : message.MESSAGE_DATA_OBJECT?.TITLE
                }
                subheader={
                    (loading)
                        ? <Skeleton width={'50%'}/>
                        : `Küldés időpontja: ${message.MESSAGE_DATA_OBJECT?.CREATED}`
                }
                className={classes.cardHeader}
            >
            </CardHeader>
            <CardContent>

                <Box>
                    {(loading)
                        ?
                        <Box style={{paddingLeft: 18, paddingRight: 18}}>
                            <Skeleton width={'100%'} count={2}/>
                        </Box>
                        :
                        <Table className={classes.table}>
                            <TableBody>
                                {getMessageHeader(message).map((row, idx) =>
                                    <TableRow key={idx}>
                                        <TableCell
                                            style={{width: 50}}
                                            className={classes.tableCell}>{row.label}:</TableCell>
                                        <TableCell className={classes.tableCell}>{row.value}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    }
                </Box>

                <Box className={classes.messageDetails}>
                    <Typography>
                        {(loading)
                            ?
                            <Skeleton width={'100%'} style={{lineHeight: 0.6}} count={4}/>
                            :
                            message.MESSAGE_DATA_OBJECT?.DETAILS}
                    </Typography>
                </Box>

                <Box className={classes.messageAttachments}>
                    <Typography variant={'h5'} className={classes.innerHeader}>
                        {(loading)
                            ? <Skeleton width={'35%'} style={{lineHeight: 1.6}}/>
                            : `Csatolmányok (${message.ATTACHMENTS.length})`
                        }
                    </Typography>

                    {(loading)
                        ? <Skeleton count={5} style={{lineHeight: 0.8}}/>
                        :
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        Fájlnév
                                    </TableCell>
                                    <TableCell style={{width: 50}} className={classes.tableCell}>
                                        Méret
                                    </TableCell>
                                    <TableCell style={{width: 50}} className={classes.tableCell}>
                                        Letöltés
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getMessageAttachments(message).map((attachment, idx) =>
                                    <TableRow key={idx} className={classes.tableRowBordered}>
                                        <TableCell
                                            className={classes.tableCellBordered}>{attachment.filename}</TableCell>
                                        <TableCell
                                            className={classes.tableCellBordered}
                                            align={'center'}>{attachment.filesize}</TableCell>
                                        <TableCell className={classes.tableCellBordered} align={'center'}>
                                            <IconButton
                                                onClick={() => onDownloadAttachment(attachment.id, attachment.filename)}
                                                className={classes.button}
                                                disableFocusRipple={true}
                                                disableTouchRipple={true}
                                                disableRipple={true}
                                            >

                                                {(downloading.includes(attachment.id))
                                                    ?
                                                    <CircularProgress color="inherit" size={16}/>
                                                    :
                                                    <DownloadIcon className={classes.buttonDownload} size={18}/>
                                                }
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>}

                </Box>

            </CardContent>
        </Card>
    );
};

export default MessageContent;
