import AuthPage from './pages/Authentication';
import GroupListPage from './pages/Groups/List';
import MessagesListPage from './pages/Messages/List';
import LogoutPage from './pages/Logout';
import EditMessagePage from './pages/Messages/Edit';
import RegistrationPage from './pages/Registration/index_old';
import ProfileSettingsPage from 'pages/Me';
import RecipientsListPage from 'pages/Recipients/List';
import PasswordReset from 'pages/PasswordReset';
import {
    Edit as EditIcon,
    Folder as FolderIcon,
    Mail as MailIcon,
    Trash2 as Trash2Icon,
    User as UserIcon,
    Users as UsersIcon,
    Archive as ArchiveIcon,
    LogOut as LogOutIcon,
    Book as BookIcon,
    Key as KeyIcon,
    Clock as Events
} from 'react-feather';
import EventsListPage from 'pages/Events';
import Login from './pages/Authentication';
import Signup from './pages/Registration';

const routes = [
    {
        exact: true,
        protectedRoute: true,
        path: '/',
        component: ({history}) => {
            history.push('/messages/incoming');
            return null;
        }
    },
    {
        exact: true,
        path: '/messages/new',
        component: EditMessagePage,
        label: 'Új üzenet',
        protectedRoute: true,
        Icon: EditIcon,
        menuGroup: 'messages',
        groupLabel: 'Üzenetek',
        area: 'left'
    },
    {
        exact: true,
        path: '/messages/reply/:id',
        component: EditMessagePage,
        label: 'Válasz üzenet',
        protectedRoute: true,
    },
    {
        exact: true,
        path: '/messages/incoming',
        component: MessagesListPage,
        // isIndexPage: true,
        label: 'Bejövő üzenetek',
        protectedRoute: true,
        Icon: FolderIcon,
        menuGroup: 'messages',
        area: 'left',
        // subRoutes: [
        //     {
        //         exact: true,
        //         path: '/messages/incoming/details/:id',
        //         component: MessagesDetailsPage,
        //         label: 'Üzenet adatlap',
        //         protectedRoute: true
        //     }
        // ]
    },
    {
        exact: true,
        path: '/messages/sent',
        component: MessagesListPage,
        label: 'Elküldött üzenetek',
        protectedRoute: true,
        Icon: MailIcon,
        menuGroup: 'messages',
        area: 'left'
    },
    {
        exact: true,
        path: '/messages/trash',
        component: MessagesListPage,
        label: 'Törölt üzenetek',
        protectedRoute: true,
        Icon: Trash2Icon,
        menuGroup: 'messages',
        area: 'left'
    },
    {
        exact: true,
        path: '/messages/archived',
        component: MessagesListPage,
        label: 'Archivált üzenetek',
        protectedRoute: true,
        Icon: ArchiveIcon,
        menuGroup: 'messages',
        area: 'left'
    },
    {
        exact: true,
        path: '/recipients-list',
        component: RecipientsListPage,
        label: 'Címjegyzék',
        protectedRoute: true,
        area: 'left',
        menuGroup: 'settings',
        groupLabel: 'Beállítások',
        Icon: BookIcon
    },
    {
        exact: true,
        path: '/groups',
        component: GroupListPage,
        isIndexPage: true,
        label: 'Csoportok',
        protectedRoute: true,
        Icon: UsersIcon,
        area: 'left',
        menuGroup: 'settings',
    },
    {
        exact: true,
        path: '/me/settings',
        component: ProfileSettingsPage,
        label: 'Profil beállítások',
        protectedRoute: true,
        Icon: UserIcon,
        area: 'left',
        menuGroup: 'settings'
    },
    {
        exact: true,
        path: '/events',
        component: EventsListPage,
        label: 'Naplóbejegyzések',
        protectedRoute: true,
        Icon: Events,
        area: 'left',
        menuGroup: 'settings'
    },
    {
        exact: true,
        path: '/me/password-reset',
        component: PasswordReset,
        label: 'Jelszó módosítás',
        protectedRoute: true,
        Icon: KeyIcon,
        area: 'left',
        menuGroup: 'settings'
    },
    {
        exact: true,
        path: '/me/logout',
        component: LogoutPage,
        label: 'Kilépés',
        protectedRoute: true,
        Icon: LogOutIcon,
        area: 'left',
        menuGroup: 'settings'
    },
    {
        exact: true,
        path: '/me/logout',
        area: 'top',
        label: 'Kilépés',
        Icon: LogOutIcon,
        protectedRoute: true,
        component: LogoutPage,
    },
    {
        exact: true,
        path: '/auth/login',
        publicRoute: true,
        component: Login
    },
    {
        exact: true,
        path: '/users/signup/:hash',
        publicRoute: true,
        component: Signup
    },
    {
        notFound: true
    }
];

export default routes;
