import {ARCHIVED_FOLDER_ID, DELETED_FOLDER_ID, INCOMING_FOLDER_ID, SENT_FOLDER_ID} from './constants/messages';

const getMessageFolderByPath = path => {
    if (path.indexOf('incoming') !== -1) {
        return INCOMING_FOLDER_ID;
    }

    if (path.indexOf('sent') !== -1) {
        return SENT_FOLDER_ID;
    }

    if (path.indexOf('trash') !== -1) {
        return DELETED_FOLDER_ID;
    }

    if (path.indexOf('archived') !== -1) {
        return ARCHIVED_FOLDER_ID;
    }
};

export default getMessageFolderByPath;