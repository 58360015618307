import * as yup from 'yup';
import 'yup-phone';
//
// function parseMaskedPhoneNumber(value, originalValue) {
//     console.log(originalValue, originalValue.replace(/\D/g,''));
//     return '+' + originalValue.replace(/\D/g,'');
// }

export default yup.object().shape({
    PHONE: yup.string()
        .required('A telefonszám megadása kötelező!'),
});
