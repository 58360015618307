import React, {useContext, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {AuthContext} from 'components/Context/AuthContext';
import {useSnackbar} from 'notistack';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Avatar,
    Typography,
    makeStyles,
    CssBaseline,
    Collapse,
    Container
} from '@material-ui/core';


import ValidationError from 'components/ValidationError/ValidationError';
import AuthService from 'services/auth-service';
import UserService from 'services/user-service';
import {parseApiError} from 'services/utils';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoCard from 'components/Cards/Info';
import LinearProgressAuto from 'components/Progress/LinearProgressAuto';
import ValidationSchema from './components/registration-validation';
import {
    Check as CheckIcon,
    Lock as LockIcon,
    UserCheck as UserCheckIcon,
} from 'react-feather';

import {
    Save as SaveIcon
} from 'react-feather';
import registrationStyle from 'pages/Registration/Styles/RegistrationStyle';

const authService = new AuthService();
const userService = new UserService();

const redirectTimeout = 6000;

const RegistrationPage = () => {
    const history = useHistory();
    const {hash: hash} = useParams();
    const [formCollapsed, setFormCollapsed] = React.useState(false);
    const [statusCollapsed, setStatusCollapsed] = React.useState(true);
    const [startProgress, setStartProgress] = React.useState(false);

    const {changeAccessToken, changeRefreshToken, updateLogInCompleted, logInCompleted} = useContext(AuthContext);
    const classes = registrationStyle();
    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setLoading(false);
    };

    const {register, control, handleSubmit, errors, reset, setValue} = useForm({
        resolver: yupResolver(ValidationSchema),
        shouldFocusError: true,
    });

    useEffect(() => {

        if (!logInCompleted) {

            userService.fetchUserDataWithHash(hash).then(userDataRes => {

                if (userDataRes.status === 200) {
                    let res = userDataRes.data;
                    if (res.success === true) {
                        setValue('EMAIL', res.data.EMAIL);
                        setValue('FIRST_NAME', res.data.FIRST_NAME);
                        setValue('LAST_NAME', res.data.LAST_NAME);
                        setValue('HASH', hash);
                        // @todo: ONLY FOR DEBUG!!!
                        setValue('REGISTRATION_CODE', '89869097');
                        setValue('PASSWORD', 'aaaAAA-.+02106928');
                        setValue('PASSWORD_RE', 'aaaAAA-.+02106928');
                    } else {
                        enqueueSnackbar(
                            'Hiba történt az adatok betöltése során!',
                            {variant: 'error'}
                        );
                    }
                }

            });
        }

    }, [hash]);


    const handleRegistrate = data => {
        setLoading(true);

        userService.createUser(data).then(userRes => {

            if (userRes.status === 200) {
                console.log(userRes);
                let res = userRes.data;
                if (res.success === true) {

                    setStatusCollapsed(!statusCollapsed);
                    setFormCollapsed(!formCollapsed);
                    setStartProgress(true);

                    setLoading(false);

                    // authorize the user
                    // changeAccessToken(res.msg.jwt_access_token);
                    // changeRefreshToken(res.msg.jwt_refresh_token);
                    //
                    // // redirect after message
                    // setTimeout(() => {
                    //     // update login state
                    //     updateLogInCompleted(true);
                    //     history.push('/messages/incoming');
                    //     enqueueSnackbar('Sikeres bejelentkezés!', {variant: 'success'});
                    // }, redirectTimeout + 1500);

                    history.push('/auth/verifyphonenumber');

                } else {
                    let error = parseApiError(res);
                    enqueueSnackbar(
                        error.message.map((e) => e.val).join('\n'),
                        {variant: 'error'});
                    setLoading(false);
                }
            }
        });

    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={loading} onClick={handleCloseLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container component="main" maxWidth="sm">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <UserCheckIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h4">
                        Új fiók létrehozása
                    </Typography>

                    <Collapse in={!statusCollapsed}>
                        <InfoCard
                            className={classes.statusCard}
                            config={{
                                'icon': <CheckIcon size={20}/>,
                                'title': 'Sikeres regisztráció!',
                                'description': 'A felhasználói fiók létrehozása sikeresen megtörtént! ' +
                                    'A rendszer most belépteti a megadott felhasználói adatokkal ' +
                                    'és automatikusan átirányítja a tárhelye kezelőfelületére.',
                                'iconWrapperClass': classes.iconWrapperClass,
                                'progress': <LinearProgressAuto progresstime={redirectTimeout} autoProgress={startProgress}/>
                            }}
                        />
                    </Collapse>


                    <Card className={classes.card}>
                        <Collapse in={!formCollapsed}>
                            <CardContent>
                                <form className={classes.form} noValidate onSubmit={handleSubmit(handleRegistrate)} autoComplete={'off'}>
                                    <Controller
                                        as={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="email"
                                        name="EMAIL"
                                        label="E-mail cím"
                                        autoComplete="email"
                                        aria-readonly={true}
                                        control={control}
                                        InputProps={{
                                            readOnly: true,
                                            style: {backgroundColor: '#f6f6f6'}
                                        }}
                                        fullWidth
                                        defaultValue=""
                                    />
                                    {errors.USERNAME && <ValidationError message={errors.USERNAME.message}/>}
                                    <div className={classes.names}>
                                        <Controller
                                            as={TextField}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            id="last_name"
                                            label="Vezetéknév"
                                            name="LAST_NAME"
                                            control={control}
                                            className={classes.nameInput1}
                                            autoFocus
                                            defaultValue=""
                                            InputProps={{
                                                autoComplete: 'off'
                                            }}
                                        />
                                        <Controller
                                            as={TextField}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            id="first_name"
                                            label="Keresztnév"
                                            name="FIRST_NAME"
                                            control={control}
                                            className={classes.nameInput2}
                                            defaultValue=""
                                            InputProps={{
                                                autoComplete: 'off'
                                            }}
                                        />
                                    </div>
                                    {errors.LAST_NAME && <ValidationError message={errors.LAST_NAME.message}/>}
                                    {errors.FIRST_NAME && <ValidationError message={errors.FIRST_NAME.message}/>}

                                    <Controller
                                        as={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="PASSWORD"
                                        label="Jelszó"
                                        type="password"
                                        id="password"
                                        control={control}
                                        defaultValue=""
                                        InputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    {errors.PASSWORD && <ValidationError message={errors.PASSWORD.message}/>}

                                    <Controller
                                        as={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="PASSWORD_RE"
                                        label="Jelszó ismét"
                                        type="password"
                                        id="password_re"
                                        control={control}
                                        defaultValue=""
                                        InputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    {errors.PASSWORD_RE && <ValidationError message={errors.PASSWORD_RE.message}/>}

                                    <Controller
                                        as={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="REGISTRATION_CODE"
                                        label="Regisztrációs kód"
                                        id="registration_code"
                                        control={control}
                                        defaultValue=""
                                        InputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    {errors.REGISTRATION_CODE && <ValidationError message={errors.REGISTRATION_CODE.message}/>}

                                    <Controller
                                        as={TextField}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        id="hash"
                                        name="HASH"
                                        label="Ellenőrző kód"
                                        autoComplete="email"
                                        aria-readonly={true}
                                        control={control}
                                        InputProps={{
                                            readOnly: true,
                                            style: {backgroundColor: '#f6f6f6'}
                                        }}
                                        fullWidth
                                        defaultValue=""
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        size={'large'}
                                        startIcon={<SaveIcon/>}
                                    >
                                        Regisztráció
                                    </Button>
                                </form>
                            </CardContent>
                        </Collapse>
                    </Card>


                </div>
            </Container>
        </>
    );
};

export default RegistrationPage;
