import React, {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import formStyle from 'components/Layout/Styles/FormStyle';
import {Card, CardContent, CardHeader, Dialog} from '@material-ui/core';
import RecipientForm from 'pages/Recipients/Edit/components/RecipientForm';
import UserService from 'services/user-service';
import SpinnerLoader from 'components/Spinner/Spinner';
import {parseApiError} from 'services/utils';

const userService = new UserService();

const NewRecipient = ({open, handleClose, fetchDataSource, dataId, setDataId}) => {
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [data, setData] = useState();
    const classes = formStyle();

    const _onClose = () => {
        setDataId(0);
        setData(false);
        setLoading(false);
        handleClose();
    };

    useEffect(() => {
        if (dataId) {
            setDataLoading(true);
            userService.fetchAddressbookData(dataId)
                .then(res => {
                    setData(res.data.data);
                    setDataLoading(false);
                })
                .catch(() => {
                    _onClose();
                });
        }
    }, [dataId]);

    const handleSubmit = (data) => {
        setLoading(true);
        if (dataId) {
            data.ID = dataId;
        }
        userService.addUserToAddressbook(data)
            .then(res => {
                const {data} = res;

                if (data.error) {
                    setLoading(false);
                    let error = parseApiError(data);
                    enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                    return;
                }

                if (data.success) {
                    setLoading(false);
                    enqueueSnackbar('Az adatok mentése sikeresen megtörént!', {variant: 'success'});
                    _onClose();
                    fetchDataSource({page: 1, pageSize: 10});
                }
            });
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <Card className={classes.dialogCard}>
                <CardHeader
                    className={classes.dialogCardHeader}
                    title={(dataId > 0) ? 'Címzett adatainak szerkesztése' : 'Új címzett hozzáadása'}
                    titleTypographyProps={{fontSize: 20}}
                    subheader="A csillaggal megjelölt mezők kitöltése kötelező"
                >
                </CardHeader>
                <CardContent>
                    {(dataLoading)
                        ? <SpinnerLoader/>
                        : <RecipientForm
                            submit={handleSubmit}
                            cancel={_onClose}
                            loading={loading}
                            data={data}
                            classes={classes}
                        />}
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default NewRecipient;
