import React from 'react';
import {render} from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './containers/App';

const rootElement = document.getElementById('root');

render(<App />, rootElement);
reportWebVitals();
