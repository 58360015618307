import {
    ARCHIVED_FOLDER_ID,
    DELETED_FOLDER_ID,
    INCOMING_FOLDER_ID,
    SENT_FOLDER_ID
} from 'utils/constants/messages';
import React from 'react';
import {IncomingActionButtons, SentActionButtons} from 'pages/Messages/List/components/ActionButtons';
import {Typography} from '@material-ui/core';
import {formatUserObject} from 'utils/common';

const getColumnsByFolderId = (folderId) => {

    return [
        {
            field: 'messages',
            headerName: 'Üzenetek',
            renderCell: (params) => {

                let obj;
                if (folderId === INCOMING_FOLDER_ID) {
                    obj = params.row.SENDER_OBJECT;
                } else if (folderId === SENT_FOLDER_ID) {
                    obj = params.row.RECIPIENTS.find(r => r.ORDER === 0);
                } else {
                    obj = params.row.OWNER_OBJECT;
                }

                return (
                    <div>
                        <Typography>
                            {formatUserObject(obj)}
                        </Typography>
                        <Typography>
                            {params.row.MESSAGE_DATA_OBJECT.TITLE}
                        </Typography>
                    </div>
                );
            },
            flex: 1,
            sortable: false,
            resizable: false,
            reorder: false,
        },
        {
            field: '',
            headerName: '',
            valueGetter: params => `${params.row.MESSAGE_DATA_OBJECT.CREATED}`,
            width: 200,
            sortable: false,
            resizable: false,
            align: 'right'
        }
    ];
};

export default getColumnsByFolderId;
