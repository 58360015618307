import {makeStyles} from '@material-ui/styles';

const massActionsStyle = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        color: theme.palette.text.listaction,
        '&:hover': {
            // backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            borderRadius: '5px',
        }
    },
    buttonDanger: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        color: theme.palette.text.listaction,
        '&:hover': {
            backgroundColor: theme.palette.danger,
            color: 'white',
            borderRadius: '10px',
        }
    },
    buttonDetails: {
        color: 'inherit'
    },
    menuItem: {
        fontSize: '10pt'
    },
    linkItem: {
        textDecoration: 'none',
        display: 'flex',
        color: 'inherit',
    }
}));

export default massActionsStyle;
