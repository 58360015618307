import React from 'react';

const Logo = (props) => (
    <img
        alt="Logo"
        src={process.env.PUBLIC_URL + '/static/images/dc-tarhely-logo.svg'} //"static/images/dc-tahely-logo.svg"
        {...props}
    />
);

export default Logo;
