import React from 'react';
import {Breadcrumbs, Typography, Box} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {getRouteArrayByPath} from 'utils/route';
import routes from 'routes';
import {Link} from 'react-router-dom';
import layoutStyle from '../Styles/LayoutStyle';

const PageContent = ({content}) => {
    const location = useLocation();
    const classes = layoutStyle();
    const paths = getRouteArrayByPath(routes, location.pathname);

    const getBreadCrumbPath = route => {
        if (route.isIndexPage && route.subRoutes) {
            return (
                <Link to={route.path} style={{textDecoration: 'none'}} key={route.path}>
                    <Typography variant="h5">
                        {route.label}
                    </Typography>
                </Link>
            );
        }

        return <Typography variant="h5" key={route.path}>{route.label}</Typography>;
    };

    return (
        <>
            <Typography variant="h3">
                {paths[paths.length-1].label}
            </Typography>
            <Box display="flex" style={{margin: '10px 0 10px 0'}}>
                <Box flexGrow={1}>
                    <Breadcrumbs
                        // separator={<NavigateNextIcon style={{strokeWidth: 1.5}} size={15} />}
                        separator={<Typography>/</Typography>}
                        className={classes.breadcrumbs}
                    >

                        <Link to={'/'} style={{textDecoration: 'none'}} key={0}>
                            <Typography variant="h5">
                                Kezdőképernyő
                            </Typography>
                        </Link>
                        {paths.map(path => getBreadCrumbPath(path))}
                    </Breadcrumbs>
                </Box>
            </Box>
            <Box>
                {content}
            </Box>
        </>
    );
};

export default PageContent;
