import React, {useEffect, useState} from 'react';
import MessagesService from 'services/messages-service';
import {parseApiError} from 'services/utils';
import {useSnackbar} from 'notistack';
import messageDetailsStyle from './components/MessageDetailsStyle';
import MessageContent from './components/MessageContent';
import MessageContentEmpty from './components/MessageContentEmpty';
import FileSaver from 'file-saver';

const messageService = new MessagesService();

const MessagesDetailsPage = ({messageId, selectedRows, messagesListLoaded}) => {

    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState([]);
    const [downloadState, setDownloadState] = useState({messageId: 0, state: null, filename: null});
    const {enqueueSnackbar} = useSnackbar();

    const classes = messageDetailsStyle();

    useEffect(() => {
        if (messageId > 0) {
            messageService.fetchMessageById(messageId)
                .then(res => {
                    const {error, success} = res.data;

                    if (error) {
                        let error = parseApiError(res.data);
                        enqueueSnackbar(error.message.map((e) => e.val).join('\n'), {variant: 'error'});
                        setLoading(false);
                        return;
                    }

                    if (success) {
                        // setTimeout(()=>{
                        setMessage(res.data.data);
                        setTimeout(()=>{
                            setLoading(false);
                        },200);
                    }
                });
        }
    }, [messageId]);

    useEffect(() => {
        if ( messagesListLoaded && !message ) {
            setTimeout(()=>{
                setLoading(false);
            },300);
        }
    },[messagesListLoaded, message]);

    const _downloadAttachment = (id, filename) => {
        messageService.downloadAttachment(id)
            .then(res => {
                FileSaver.saveAs(res.data, filename);
                setDownloadState({messageId: id, state: 'downloaded', filename: null});
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {

        if (downloadState.messageId > 0 && downloadState.state === 'downloading') {
            _downloadAttachment(downloadState.messageId, downloadState.filename);
        }

        if (downloadState.messageId > 0 && downloadState.state === 'downloaded') {
            setDownloading(
                downloading.filter(value => value !== downloadState.messageId)
            );
        }

    }, [downloadState]);

    const downloadAttachment = (id, filename) => {
        if (downloading.includes(id)) {
            return;
        }
        setDownloading(downloading.concat([id]));
        setDownloadState({messageId: id, state: 'downloading', filename: filename});
    };

    // console.log(message, loading);
    return (
        <>
            {(loading || message)
                ?
                <MessageContent
                    classes={classes}
                    message={message}
                    downloading={downloading}
                    loading={loading}
                    onDownloadAttachment={downloadAttachment}
                />
                :
                <MessageContentEmpty classes={classes}/>
            }
        </>
    );
};

export default MessagesDetailsPage;
